import { FormWithRedux } from '../../FormWithRedux';
import { ACCBISTPCognitiveScaleForm } from './ACCBISTP-CognitiveScaleForm';
import { ACCBISTPOverallImpactScaleForm } from './ACCBISTP-OverallImpactScaleForm';
import { ACCBISTPPhysicalScaleForm } from './ACCBISTP-PhysicalScaleForm';
import { ACCBISTPPost24HourScaleForm } from './ACCBISTP-Post24HourScaleForm';
import { ACCBISTPVestibularocularScaleForm } from './ACCBISTP-VestibularocularScaleForm';

export class ACCBISTPSymptonScaleForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('bistSymptomScale', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'physicalBistSubform': 
                return new ACCBISTPPhysicalScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'vestibularocularBistSubform':
                return new ACCBISTPVestibularocularScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'cognitiveBistSubform': 
                return new ACCBISTPCognitiveScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'post24HoursBistSubform': 
                return new ACCBISTPPost24HourScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'overallImpactScoreSubform':
                return new ACCBISTPOverallImpactScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'post24HoursBistSubform' || identifier == 'overallImpactScoreSubform') {
            let injuryDateString = this.getValue('injuryOccuranceDateTime');  
            if (injuryDateString != undefined && injuryDateString != null) {
                let injuryDate = new Date(injuryDateString);
                let now = new Date();
                let msBetweenDates = Math.abs(injuryDate.getTime() - now.getTime());
                let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
                return hoursBetweenDates > 24;
            }
            else {
                return false;
            }
        }
        
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'bistDateTime') {
            return true;
        }

        return false;
    }

    getValueType(identifier) {
        if (identifier == 'bistDateTime') {
            return 'date';
        }

        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }

    
}