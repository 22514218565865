import { FormWithRedux } from '../../FormWithRedux';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormsStyles, FormsUIGlossary } from '../../../waferJS/FormsUI/FormsUI';
import React from 'react';

export class ACCBISTPGCSForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater, index) {
        super('gcsSectionSubform' + index, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
        this.index = index;
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'totalGCSScore' + this.index) {
            let value = this.getValue('totalGCS' + this.index);

            if (value == null || value == undefined) {
                return false;
            }
        }

        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        let allFineCheck = this.getValue('neuroObsOkayCheck' + this.index);
        
        if (allFineCheck != null && allFineCheck != undefined && allFineCheck == true) {
            return false;
        }

        return true;
    }

    getValueType(identifier) {
        if (identifier == 'totalGCSScore' + this.index) {
            return 'double';
        }
        return 'string';
    }

    submissionValidation() {
        return [];
    }

    valueUpdated(identifier, value, forceSaveOption = false) {
        if (identifier === 'verbalScore' + this.index) {
            if (!this.isOrientationNormal(this)) {
                // Skip verbal score change if one of the orientation items is false, to keep score "Confused"
                return;
            }
        }
        super.valueUpdated(identifier, value, forceSaveOption);
    }

    getBadgeValue(identifier) {
        if (identifier == 'totalGCSScore' + this.index) {
            let value = this.getValue('totalGCS' + this.index);

            if (value != null && value != undefined) {
                return [{
                    color: FormsStyles.black,
                    title: 'Total GCS',
                    value: value.toString(),
                    solid: false
                }];
            }
            else {
                return [];
            }
        }

        return [];
    }

    editingValidation(identifier) {
        if (identifier === 'verbalScore' + this.index) {
            let self = this;
    
            let valueConflict = new FormValidation('VerbalScoreConflict', function () {
                if (self.isOrientationNormal(self)) {
                    return [];
                } else {
                    return [new FormValidationError(FormValidationError.FormErrorType.warning, 'AWPTA suggests patient is confused')];
                }
            });
            return [valueConflict];
        }

        return [];
    }

    isOrientationNormal(self) {
        let orientationIndex = self.index;
        if (self.index === "") {
            // Check the latest A-WPTA index for Neuro Obs form
            if(self.getValue("awptaCheck5DateTime")) {
                orientationIndex = "5";
            } else if (self.getValue("awptaCheck4DateTime")) {
                orientationIndex = "4";
            } else if (self.getValue("awptaCheck3DateTime")) {
                orientationIndex = "3";
            } else if (self.getValue("awptaCheck2DateTime")) {
                orientationIndex = "2";
            } else if (self.getValue("awptaCheck1DateTime")) {
                orientationIndex = "1";
            }
        }
        let orientationChecks = [
            self.getValue('whatIsYourNameCheck' + orientationIndex),
            self.getValue('nameOfThisPlaceCheck' + orientationIndex),
            self.getValue('whyAreYouHereCheck' + orientationIndex),
            self.getValue('whatMonthAreWeInCheck' + orientationIndex),
            self.getValue('whatYearAreWeInCheck' + orientationIndex)];
    
        if(!orientationChecks.includes(null) && !orientationChecks.includes(undefined) && orientationChecks.includes(false)) {
            return false;
        } else {
            return true;
        }
    }
}