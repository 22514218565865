import documentVersions from '../../config/documentVersions.json';
import { PathwayFormatter } from '../PathwayFormatter';
import { PathwayReferenceHelper } from '../PathwayReferenceHelper';
import { ENVIRONMENT } from '../../config/config';
import { redux } from '../../App';
import { calculateEWS, TotalEWS  } from './custom types/EWS';
import { CalculationModel } from './custom types/CalculationModel';
import { ACCBISTPCalculationHelper } from './helpers/ACCBISTPCalculationsHelper';
import { ACCBISTPPathwayReference } from './ACCBISTP-PathwayReference';

const sectionLine = '=======================================\n';
const sectionBreak = '\n\n';

export class ACCBISTPFormatter {
  static formatAsPlainText(formLoader, workflow) {
    var forms = [
      formLoader.form(workflow.pathwayUuid, 'patientDetailsSubform'),
      formLoader.form(workflow.pathwayUuid, 'injuryDetailsSubform'),
      formLoader.form(workflow.pathwayUuid, 'clinicalAssessmentSubform'),
      // formLoader.form(workflow.pathwayUuid, 'awptaCheck1Subform'),
      // formLoader.form(workflow.pathwayUuid, 'awptaCheck2Subform'),
      // formLoader.form(workflow.pathwayUuid, 'awptaCheck3Subform'),
      // formLoader.form(workflow.pathwayUuid, 'awptaCheck4Subform'),
      // formLoader.form(workflow.pathwayUuid, 'awptaCheck5Subform'),
      formLoader.form(workflow.pathwayUuid, 'bistSymptomScale'),
      // formLoader.form(workflow.pathwayUuid, 'gcsSubform'),
      // formLoader.form(workflow.pathwayUuid, 'vitalSignsSubform'),
      formLoader.form(workflow.pathwayUuid, 'ctBrainSubform'),
      formLoader.form(workflow.pathwayUuid, 'resultsForm')
    ];

    var formattedString = '';

    for (let form of forms) {
      let details = form.detailString(false);
      if (details !== null && details !== undefined) {
          formattedString += form.title.toUpperCase() + '\n';
          formattedString += sectionLine;
          formattedString += details;
          formattedString += sectionBreak;
      }
    }

    formattedString += 'DIAGNOSIS\n';
    formattedString += sectionLine;
    formattedString += (formLoader.form(workflow.pathwayUuid, 'resultsForm')).getPlainTextActions(false, ['diagnosisConcussionAction', 'diagnosisConcussionConfoudningFactorsAction']);

    formattedString += 'INSIGHTS\n';
    formattedString += sectionLine;
    // formattedString += 'A-WPTA Score: ' + getCalculationBadge(formLoader, workflow, CalculationModel.CalculationType.awptaScoreCalculation, 'awptaScoreCalculation') + '\n';
    // formattedString += sectionBreak;
    formattedString += 'BIST Symptom Score: ' + getCalculationBadge(formLoader, workflow, CalculationModel.CalculationType.bistScoreCalculation, 'bistScoreCalculation') + '\n';
    formattedString += sectionBreak;

    formattedString += 'BIST Dominant Cluster: ' + getCalculationBadge(formLoader, workflow, CalculationModel.CalculationType.bistDominantSymptomCluster, 'bistDominiantSymptomCluster') + '\n';
    formattedString += sectionBreak;
    formattedString += 'Concussion Risk Category: ' + getCalculationBadge(formLoader, workflow, CalculationModel.CalculationType.concussionRiskCategory, 'concussionRiskCategory') + '\n';
    formattedString += sectionBreak;

    formattedString += 'INVESTIGATIONS\n';
    formattedString += sectionLine;
    formattedString += (formLoader.form(workflow.pathwayUuid, 'resultsForm')).getPlainTextActions(false, ['ctHead']);

    formattedString += 'MANAGEMENT\n';
    formattedString += sectionLine;
    formattedString += (formLoader.form(workflow.pathwayUuid, 'resultsForm')).getPlainTextActions(false, ['seniorMedicalReview', 'seekNeuroscienceReview', 'neuroObsManagement']);

    formattedString += 'DISCHARGE PLAN\n';
    formattedString += sectionLine;
    formattedString += (formLoader.form(workflow.pathwayUuid, 'resultsForm')).getPlainTextActions(false, 
        [
          'accReferralLowRiskAction', 
          'accReferralModerateRiskAction', 
          'physioTherapistReferralAction',
          'referralHighRiskActionForPatientScore',
          'referralModerateRiskActionForPatientScore',
          'referralLowVestibularRiskActionForPatientScore',
          'referralLowRiskActionForPatientScore',
        ]);

        let noteDetails = (formLoader.form(workflow.pathwayUuid, 'notesForm')).detailString(false);
        if (noteDetails != null && noteDetails != undefined) {
          formattedString += 'NOTES\n';
          formattedString += sectionLine;
          formattedString += noteDetails;
          formattedString += sectionBreak;
        }

      return formattedString;
  }
}

function getEmptyValue(identifier){
  let reference = PathwayReferenceHelper.reference(identifier, ACCBISTPPathwayReference.data);
  return '[' + reference.title + ']';
}

function getCalculationBadge(formLoader, workflow, calculationType, identifier) {
  let mainForm = formLoader.form(workflow.pathwayUuid, 'mainForm');
  let error = ACCBISTPCalculationHelper.calcualtionError(identifier, workflow.rawData, mainForm);
  
  if(error != null){
      return error.message;
  }

  let pathwayProgress = mainForm.completionProgress();
  if(pathwayProgress == null) {
      return getEmptyValue(identifier);
  }

  let calculations = redux.store.getState().calculationReducer.calculations['accbist02'];

  let calculation = ACCBISTPCalculationHelper.getLatestCalculation(calculationType, calculations);

  if (calculation == null) { return getEmptyValue(identifier); }

  calculation = CalculationModel.rehydrate(calculation);

  return calculation.valueString + (calculation.rating != null ? ' (' + calculation.rating + ')' : '');
}

function getEnvironmentName() {
  switch (ENVIRONMENT) {
      case 'PROD':
          break;
      case 'DEV':
          return 'Dev ';
      case 'TEST':
          return 'Test ';
      case 'STAGING':
          return 'Staging ';
      case 'DEMO':
          return 'Demo ';
  }
}