// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PathwayReferenceHelper } from '../../PathwayReferenceHelper';
import { ACCBISTPPathwayReference } from '../ACCBISTP-PathwayReference';
import { FormsContentType, FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { ACCBISTPCalculationHelper } from '../helpers/ACCBISTPCalculationsHelper';
import { PrintHeader } from '../../../components/shared/PrintHeader';
import { calculateEWS, EWSCategory, TotalEWS } from '../custom types/EWS';

import moment from 'moment';

import '../../../styles/components/formSummary.scss';
import { CalculationModel } from '../custom types/CalculationModel';

// COMPONENT
class ACCBISTPSummary extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            complete: 0,
            total: 0,
            actions: [],
            exportHCSVisible: false,
            data: ACCBISTPPathwayReference.data,
            mainForm: props.formLoader.form(this.props.pathwayId, 'mainForm') || null
        };

        this.formatDate = this.formatDate.bind(this);
        this.getItemsInSection = this.getItemsInSection.bind(this);
        this.getReadableValue = this.getReadableValue.bind(this);
        this.getMissingDetail = this.getMissingDetail.bind(this);
        this.formatList = this.formatList.bind(this);
        this.getDisparateItemsAsList = this.getDisparateItemsAsList.bind(this);
        this.getCalculationBadge = this.getCalculationBadge.bind(this);
        this.getRedFlagsBadge = this.getRedFlagsBadge.bind(this);
        this.getSummary = this.getSummary.bind(this);
        this.areRedFlagPresents = this.areRedFlagPresents.bind(this);
        this.getMandatoryDetail = this.getMandatoryDetail.bind(this);
        this.getEWSBadge = this.getEWSBadge.bind(this);
        this.getTotalEWSBadge = this.getTotalEWSBadge.bind(this);
        this.getBadgeValue = this.getBadgeValue.bind(this);
        this.stripSpecialChars = this.stripSpecialChars.bind(this);
        this.getReadableSectionValues = this.getReadableSectionValues.bind(this);
        this.getReadableSubsectionValues = this.getReadableSubsectionValues.bind(this);
        this.getValue = this.getValue.bind(this);
        this.loadFormSummaries = this.loadFormSummaries.bind(this);
        this.loadFormSummary = this.loadFormSummary.bind(this);
    }

    componentDidMount() {
        let pathwayProgress = this.state.mainForm.completionProgress(['followUpBloodsDateAndTime', 'followUpBloodsTroponin']);
        if (pathwayProgress != null) {
            this.setState({
                complete: pathwayProgress.current,
                total: pathwayProgress.total
            });
        } else {
            this.setState({
                complete: 0,
                total: 0
            });
        }

        let formActions = this.props.actions[this.props.pathwayId];
        if (formActions != null) {
            this.setState({ actions: formActions });
        }
    }

    getReadableDate(workflow, identifier, format, isDefaultStyle, isMandatory) {
        let date = workflow.rawData[identifier];
        if (date == null) {
            if (!isMandatory) {
                return this.getMissingDetail(identifier);
            }
            return this.getMandatoryDetail(identifier);
        }
        return <label className={'list-detail '}>{this.formatDate(date, format)}</label>;
    }

    formatDate(date, format) {
        if (date == null) { return null; }
        return moment(date).format(format);
    }

    getMissingDetail(identifier) {
        let reference = PathwayReferenceHelper.reference(identifier, this.state.data);
        return <label className={'not-present list-detail'}>[{reference.title}]</label>;
    }

    getMandatoryDetail(identifier) {
        let reference = PathwayReferenceHelper.reference(identifier, this.state.data);
        return <label className={'absent list-detail'}>[{reference.title}]</label>;
    }

    getItemsInSection(workflow, identifiers, valueState, tag, openingString, closingString, lineBreak) {
        let filteredItems = [];
        for (let identifier of identifiers) {
            let listReference = PathwayReferenceHelper.reference(identifier, this.state.data);
            filteredItems = filteredItems.concat(
                listReference[PathwayReferenceHelper.Type.sectionChildIDs].filter((itemID) => {
                    return workflow.rawData[itemID] == valueState;
                })
            );
        }

        if (filteredItems.length == 0) { return ''; }

        return this.formatList(filteredItems, tag, openingString, closingString, lineBreak, workflow);
    }

    getDisparateItemsAsList(workflow, identifiers, valueState, tag, openingString, closingString, lineBreak) {
        let filteredItems = identifiers.filter((itemID) => {
            return workflow.rawData[itemID] == valueState;
        });

        if (filteredItems.length == 0) { return ''; }

        return this.formatList(filteredItems, tag, openingString, closingString, lineBreak);
    }

    formatList(identifiers, tag, openingString, closingString, lineBreak, workflow) {
        return (<label className={'list-detail ' + tag} key={tag + identifiers[0]}>
            {openingString} {identifiers.map((itemID) => {
                if (!lineBreak) {
                    return (
                        (PathwayReferenceHelper.reference(itemID, this.state.data).title.includes(',') ? '\'' : '') + PathwayReferenceHelper.reference(itemID, this.state.data).title + (PathwayReferenceHelper.reference(itemID, ACCBISTPPathwayReference.data).title.includes(',') ? '\'' : '') + (itemID != identifiers[identifiers.length - 1] ? ', ' : '')
                    );
                }

                return (
                    <span className="detail-list-span-vertical" key={itemID}>{PathwayReferenceHelper.reference(itemID, this.state.data).title + (itemID != identifiers[identifiers.length - 1] ? ',' : '')} <br /></span>
                );
            })}{closingString}
        </label>);
    }

    getReadableSectionValues(workflow, identifiers, title, id) {
        return (
            <div id={id} className='summary-section avoidPageBreak'>
                <h2 className='detail-list-title'>{title}</h2>
                <div className='detail-list'>
                    {identifiers.map((itemID) => {
                        return (
                            this.getValue(workflow, itemID)
                        );
                    })}
                </div>
            </div>

        );
    }

    getReadableSubsectionValues(workflow, identifiers, title, id) {
        return (
            <div>
                <label className='actions-list-title'>{title}</label>
                {identifiers.map((itemID) => {
                    return (
                        this.getValue(workflow, itemID)
                    );
                })}
            </div>
        );
    }

    getValue(workflow, identifier) {
        return (<div key={identifier} >
            <label className='list-detail'>{PathwayReferenceHelper.reference(identifier, this.state.data).title}: &#160;</label>
            <label className='list-detail'>{this.getReadableValue(workflow, identifier, false)}</label>
        </div>);
    }

    getReadableValue(workflow, identifier, isMandatory) {
        let reference = PathwayReferenceHelper.reference(identifier, this.state.data);
        let value = workflow.rawData[identifier];
        let unit = reference[PathwayReferenceHelper.Type.textEntryUnitText];
        if (value == null) {
            if (isMandatory) {
                return this.getMandatoryDetail(identifier);
            }

            return this.getMissingDetail(identifier);
        }

        switch (reference[PathwayReferenceHelper.Type.controlType]) {
            case FormsContentType.ControlType.plain:
            case FormsContentType.ControlType.badge:
            case FormsContentType.ControlType.calculation:
            case FormsContentType.ControlType.action:
            case FormsContentType.ControlType.picker:
            case FormsContentType.ControlType.weblink:
            case FormsContentType.ControlType.textEntry: {
                return value + (unit == null ? '' : unit);
            }
            case FormsContentType.ControlType.radio:
                return value == true ? 'Yes' : 'No';
            case FormsContentType.ControlType.selection:
                if (reference[PathwayReferenceHelper.Type.selectionType] == FormsContentType.SelectionType.explicit) {
                    return value == true ? 'Yes' : 'No';
                } else if (reference[PathwayReferenceHelper.Type.selectionType] == FormsContentType.SelectionType.simple) {
                    let optionReference = PathwayReferenceHelper.reference(value, this.state.data);
                    return optionReference.title;
                }
        }

        if (isMandatory) {
            return this.getMandatoryDetail(identifier);
        }

        return this.getMissingDetail(identifier);
    }

    getCalculationBadge(workflow, calculationType, identifier) {
        let error = ACCBISTPCalculationHelper.calcualtionError(identifier, workflow.rawData, this.state.mainForm);

        if (error != null) {
            return <label className='list-detail' style={{ color: FormsStyles.orange }}>{error.message}</label>;
        }

        if (this.state.complete == 0 && this.state.total == 0) {
            return this.getMandatoryDetail(identifier);
        }

        if (((calculationType == CalculationModel.CalculationType.riskCategory) && (this.state.complete / this.state.total) != 1)) {
            return this.getMandatoryDetail(identifier);
        }

        let calculation = ACCBISTPCalculationHelper.getLatestCalculation(calculationType, this.props.calculations[this.props.pathwayId]);
        if (calculation == null) { return this.getMandatoryDetail(identifier); }

        let badgeStyle = {
            backgroundColor: calculation.badgeColor == null ? FormsStyles.backgroundColor : calculation.badgeColor,
            color: calculation.badgeColor == null ? 'black' : 'white',
        };

        return <label className='tag' style={badgeStyle}>{calculation.valueString}</label>;
    }

    getAction(action) {
        let title = action.title == null ? PathwayReferenceHelper.reference(action.identifier, this.state.data).title : action.title;
        let detail = action.detail == null ? PathwayReferenceHelper.reference(action.identifier, this.state.data).detail : action.detail;
        title = this.stripSpecialChars(title);
        detail = this.stripSpecialChars(detail);
        return (
            <span key={action.uuid} className='detail-list-span-vertical'>
                <label className='list-title'>{title}</label>
                <label className='list-detail'>{detail}</label>
            </span>
        );
    }

    getActions() {
        let sections = [
            'diagnosisSection',
            'investigationSection',
            'managementSection',
            'referralSection'
        ];

        return (
            <span className="detail-list-span-vertical">
                {
                    sections.map((section) => {
                        let actionsForSections = PathwayReferenceHelper.reference(section, this.state.data)[PathwayReferenceHelper.Type.sectionChildIDs];

                        return (
                            actionsForSections.map((childID) => { return this.state.actions.filter((action) => { return action.identifier == childID; }).length > 0 ? true : null; }).filter(n => n).length > 0 &&
                            <div key={section} style={{ marginBottom: sections[sections.length - 1] == section ? '0' : '1em' }}>
                                <div className="actions-list-title">{PathwayReferenceHelper.reference(section, this.state.data).title}</div>
                                {actionsForSections.map((actionIdentifier) => {
                                    let filteredActions = this.state.actions.filter((action) => { return action.identifier == actionIdentifier; });

                                    if (filteredActions.length > 0) {
                                        let action = filteredActions[0];

                                        let title = action.title == null ? PathwayReferenceHelper.reference(action.identifier, this.state.data).title : action.title;
                                        let detail = action.detail == null ? PathwayReferenceHelper.reference(action.identifier, this.state.data).detail : action.detail;
                                        title = this.stripSpecialChars(title);
                                        detail = this.stripSpecialChars(detail);

                                        return <span key={action.uuid} className='detail-list-span-vertical'>
                                            <label className='list-title'>{title}</label>
                                            <label className='list-detail'>{detail}</label>
                                        </span>;
                                    }
                                    return null;
                                })}
                            </div>
                        );
                    })
                }
            </span>
        );
    }

    getRedFlagsBadge(workflow) {
        let totalRedFlags = workflow.rawData['totalRedFlags'];
        if (totalRedFlags != null && totalRedFlags > 0) {
            return (<label className='tag' style={{ backgroundColor: 'red' }}>{totalRedFlags + ' ALERT' + (totalRedFlags == 1 ? '' : 'S')}</label>);
        }
        return ('');
    }

    areRedFlagPresents(workflow) {
        let redflags = [
            'reducedGCSRedFlag',
            'ewsCriteriaRedflag',
            'ctCriteraRedflag',
            'highRiskSymptomsOrSignsRedFlag'
        ];

        return redflags.map((redFlag) => {
            return workflow.rawData[redFlag] != null ? true : null;
        }).filter(n => n).length > 0;

    }

    sectionContainsValue(workflow, values, identifiers) {
        return identifiers.map((identifier) => {
            return values.includes(workflow.rawData[identifier]);
        }).includes(true);
    }

    getEWSBadge(workflow, identifier) {
        let value = workflow.rawData[identifier];
        let ews = calculateEWS(identifier + 'EWS', value);

        if (ews != null && ews.type != EWSCategory.Type.white) {
            return (<label className='tag' style={{ backgroundColor: ews.color }}>{ews.score}</label>);
        }
        return null;
    }

    getTotalEWSBadge(workflow) {
        let identifiers = [
            'heartRateEWS',
            'bloodPressureEWS',
            'bodyTemperatureEWS',
            'oxygenSaturationEWS',
            'respiratoryRateEWS',
            'supplementalOxygenEWS',
            'levelOfConsciousnessEWS'
        ];
        let categories = identifiers.map((identifier) => {
            let value = workflow.rawData[identifier.replace('EWS', '')];
            return calculateEWS(identifier, value);
        }).filter(n => n);

        if (categories.length == 0) {
            return null;
        }
        let totalEWS = new TotalEWS(categories);
        if (totalEWS != null) {
            return (<label className='tag' style={{ backgroundColor: totalEWS.sum > 0 ? totalEWS.color : FormsStyles.backgroundColor, color: totalEWS.sum <= 0 ? totalEWS.color : 'white' }}>{totalEWS.title}</label>);
        }
        return null;
    }

    getVitals(workflow) {
        var identifiers = [
            'heartRate',
            'bloodPressure',
            'bodyTemperature',
            'oxygenSaturation',
            'respiratoryRate',
            'supplementalOxygen',
            'levelOfConsciousness'
        ];

        let values = identifiers.map((identifier) => {
            if(workflow.rawData[identifier] != null && workflow.rawData[identifier] != undefined) {
                switch (identifier) {
                    case 'heartRate':
                        return <span key={identifier} style={{ marginRight: '0.5em' }}>HR: {this.getReadableValue(workflow, identifier, true)},</span>;
                    case 'bloodPressure':
                        return <span key={identifier} style={{ marginRight: '0.5em' }}>BP:  {this.getReadableValue(workflow, identifier, true)},</span>;
                    case 'bodyTemperature':
                        return <span key={identifier} style={{ marginRight: '0.5em' }}>T: {this.getReadableValue(workflow, identifier, true)},</span>;
                    case 'oxygenSaturation':
                        return <span key={identifier} style={{ marginRight: '0.5em' }}>SpO₂: {this.getReadableValue(workflow, identifier, true)},</span>;
                    case 'respiratoryRate':
                        return <span key={identifier} style={{ marginRight: '0.5em' }}>RR: {this.getReadableValue(workflow, identifier, true)},</span>;
                    case 'supplementalOxygen':
                        return <span key={identifier} style={{ marginRight: '0.5em' }}>Supplemental oxygen: {this.getReadableValue(workflow, identifier, true)},</span>;
                    case 'levelOfConsciousness':
                        return <span key={identifier}>Level of consciousness: {this.getReadableValue(workflow, identifier, true)}</span>;
                }
            }
            else {
                return null;
            }
        });

        values = values.filter(n => n);

        if(values.length == 0) {
            return null;
        }

        return <span> {values.map((vital) => {
            return vital;
        })} </span>;
    }

    getBadgeValue(identifier, workflow) {
        switch (identifier) {
            default:
                return <span>missing</span>;
        }
    }

    stripSpecialChars(string) {
        if (!string.includes('#@')) { return string; }
        let components = string.split('#@');
        let strippedString = '';
        for (let component of components) {
            if (component.includes('^')) {
                let subComponents = component.split('^');
                strippedString += subComponents[1];
            } else {
                strippedString += component;
            }
        }
        return strippedString;
    }

    loadFormSummaries(workflow) {

        var formIds = [
            this.props.formLoader.form(workflow.pathwayUuid, 'patientDetailsSubform'),
            this.props.formLoader.form(workflow.pathwayUuid, 'injuryDetailsSubform'),
            this.props.formLoader.form(workflow.pathwayUuid, 'clinicalAssessmentSubform'),
            this.props.formLoader.form(workflow.pathwayUuid, 'bistSymptomScale'),
            this.props.formLoader.form(workflow.pathwayUuid, 'gcsSubform'),
            this.props.formLoader.form(workflow.pathwayUuid, 'ctBrainSubform')];

        const forms = formIds
            .map(formId => this.props.formLoader.form(workflow.pathwayUuid, formId))
            .filter(form => form !== null); // Filter out null forms

        if (forms.length === 0) {
            return null;
        }

        return (
            <div>
                {forms.map((form) => {
                    return this.loadFormSummary(form);
                })}
            </div>
        );
    }

    loadFormSummary(subform) {
        let detail = subform.detailHTML(false);

        if (detail == null) {
            return null;
        } 
        else {
            return (
                <div key={subform.formIdentifier} id={subform.formIdentifier} className='summary-section avoidPageBreak'>
                    <h2 className='detail-list-title'>{subform.title}</h2>
                    <div className='detail-list'>
                        { detail }
                    </div>
                </div>
            );
        }
    }

    loadResearchConsent(workflow) {
        let researchForm = this.props.formLoader.form(workflow.pathwayUuid, 'researchForm');
        if (researchForm == null) return null;
    
        return (
            <div id='research-consent' className='summary-section avoidPageBreak'>
                <h2 className='detail-list-title'>Research Consent</h2>
                <div className='detail-list'>
                    <div>
                        <label className='list-detail' style={{ fontWeight: "bold" }}>Anonymous Data Collection: &#160;</label>
                        <label className='list-detail'>
                            {workflow.rawData.anonymousDataPermission === null || workflow.rawData.anonymousDataPermission === undefined ? 
                                "opt out" : 
                                this.getReadableValue(workflow, 'anonymousDataPermission', false)}
                        </label>
                    </div>
                    <div>
                        <label className='list-detail' style={{ fontWeight: "bold" }}>Contact for Future Research: </label>
                        <label className='list-detail'>
                            {workflow.rawData.contactDetailsPermission === null || workflow.rawData.contactDetailsPermission === undefined ? 
                                "opt out" : 
                                this.getReadableValue(workflow, 'contactDetailsPermission', false)}
                        </label>
                    </div>
                    <div>
                        <label className='list-detail' style={{ fontWeight: "bold" }}>Research Consent: </label>
                        <label className='list-detail'>
                            {workflow.rawData.contactDetailsPermission === null || workflow.rawData.contactDetailsPermission === undefined ? 
                                "opt out" : 
                                this.getReadableValue(workflow, 'researchConsentContent', false)}
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    getSummary(workflow) {
        let vitals = this.getVitals(workflow);
        return (
            <div ref={node => this.node = node} className='form-summary-container form-page-container'>
                <div className='form-summary' ref={node => this.node = node}>
                    <div id='cardiac-chestpain'>

                        <PrintHeader forceVisibility={true} pathwayId={this.props.pathwayId} />

                        {this.loadResearchConsent(workflow)}

                        {
                            this.loadFormSummaries(workflow)
                        }

                        {
                            vitals &&
                            <div id='vitals' className='summary-section avoidPageBreak'>
                                <span className={'detail-list-span-horizontal'}><h2 className='detail-list-title'>Vitals</h2> {this.getTotalEWSBadge(workflow)}</span>
                                <div className='detail-list'>
                                    {vitals}
                                </div>
                            </div>
                        }
                       

                        <div id='red-flags' className='summary-section avoidPageBreak'>
                            <span className='detail-list-span-horizontal'><h2 className='detail-list-title'>Red flags</h2>{this.getRedFlagsBadge(workflow)}</span>
                            <div className='detail-list'>
                                <span className={'detail-list-span-vertical'}>
                                    {this.areRedFlagPresents(workflow) &&
                                        <span className='detail-list-span-vertical'>
                                            {this.sectionContainsValue(workflow, [true, null], ['reducedGCSRedFlag', 'ewsCriteriaRedflag', 'ctCriteraRedflag', 'highRiskSymptomsOrSignsRedFlag']) &&
                                                <span className='detail-list-span-vertical'>
                                                    {this.getDisparateItemsAsList(workflow, ['reducedGCSRedFlag', 'ewsCriteriaRedflag', 'ctCriteraRedflag', 'highRiskSymptomsOrSignsRedFlag'], true, 'present-items', '', '', true)}
                                                </span>
                                            }
                                        </span>
                                    }
                                    {!this.areRedFlagPresents(workflow) && <label className='list-detail not-present'>No red flags</label>}
                                </span>
                            </div>
                        </div>

                        <div id='insights' className='summary-section avoidPageBreak'>
                            <h2 className='detail-list-title'>Outcome</h2>
                            <div className='detail-list'>
                                {/* <span className='detail-list-span-horizontal'>
                                    <label className='list-title'>{'A-WPTA Score'}</label>
                                    {this.getCalculationBadge(workflow, CalculationModel.CalculationType.awptaScore, 'awptaScoreCalculation')}
                                </span> */}

                                <span className='detail-list-span-horizontal'>
                                    <label className='list-title'>{'BIST Symptom Score'}</label>
                                    {this.getCalculationBadge(workflow, CalculationModel.CalculationType.bistScoreCalculation, 'bistScoreCalculation')}
                                </span>
{/* 
                                <span className='detail-list-span-horizontal'>
                                    <label className='list-title'>{'BIST Weighted Risk'}</label>
                                    {this.getCalculationBadge(workflow, CalculationModel.CalculationType.bistMentalHealthScoreCalculation, 'bistMentalHealthScoreCalculation')}
                                </span> */}
                                
                                <span className='detail-list-span-horizontal'>
                                    <label className='list-title'>{'BIST Dominant Cluster'}</label>
                                    {this.getCalculationBadge(workflow, CalculationModel.CalculationType.bistDominantSymptomCluster, 'bistDominiantSymptomCluster')}
                                </span>

                                {/* <span className='detail-list-span-horizontal'>
                                    <label className='list-title'>{'Concussion Risk Category'}</label>
                                    {this.getCalculationBadge(workflow, CalculationModel.CalculationType.concussionRiskCategory, 'concussionRiskCategory')}
                                </span> */}
                            </div>
                        </div>

                        <div id='actions' className='summary-section avoidPageBreak'>
                            <h2 className='detail-list-title'>Actions</h2>
                            <div className='detail-list'>
                                <span className='detail-list-span-vertical'>
                                    {this.state.actions.length > 0 &&
                                        this.getActions()
                                    }
                                    {this.state.actions.length == 0 && <label className='list-detail not-present'>No actions</label>}
                                </span>
                            </div>
                        </div>

                        {
                            this.loadFormSummary(this.props.formLoader.form(workflow.pathwayUuid, 'notesForm'))
                        }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let params = new URLSearchParams(window.location.search);
        let exportType = params.get('type');
        let workflow = this.props.workflows[this.props.pathwayId];
        let formSummaryFormat;
        if (workflow != null) {
            if (exportType == 'JSON' || exportType == 'json') {
                formSummaryFormat = <div>{JSON.stringify(this.state.workflow.rawData)}</div>;
            } else {
                formSummaryFormat = this.getSummary(workflow);
            }
        }

        return (
            <div>
                {formSummaryFormat}
            </div>
        );
    }
}

ACCBISTPSummary.propTypes = {
    workflows: PropTypes.object.isRequired,
    calculations: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    pathwayId: PropTypes.string.isRequired
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed } = state.workflowReducer;
    const { calculations } = state.calculationReducer;
    const { actions } = state.actionsReducer;
    return { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed, calculations, actions };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({}, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ACCBISTPSummary);

// EXPORT COMPONENT

export { hoc as ACCBISTPSummary };