import { redux } from '../../../App';
import { saveCalculation } from '../../../components/state/actions/CalculationActions';
import { CalculationModel } from '../custom types/CalculationModel';
import { calcAWPTA } from '../../../helpers/AWPTACalc';
import { BISTPatientScoreCalculator } from "../../concussion01/Calculations/BISTPatientScoreCalculator";

export class ACCBISTPCalculations {


    update(pathwayId, workflow, calculations, form) {
       
        let mainForm = form.parent != null ? form.parent : form;

        let progress = mainForm.completionProgress();

        let formComplete = (progress.current / progress.total) == 1;
        formComplete = true;

        const patientScoreCalculator = new BISTPatientScoreCalculator();
        // Scoring criteria 1 : age_weight
        patientScoreCalculator.addCalculation("patientAge", workflow.rawData['patientAge']);
        // Scoring criteria 2 : trauma_weight
        patientScoreCalculator.addCalculation("confoundingFactors", workflow.rawData['confoundingFactors'] === true && workflow.rawData['psychologicalOrEmotionalTrauma'] === true);
        // Scoring criteria 3 : vomit_weight
        if(workflow.rawData['vomiting'] === true) {
            patientScoreCalculator.addCalculation("numberOfVomittingEpisodes", workflow.rawData['numberOfVomittingEpisodes']);
        }
        // Scoring criteria 4 : LOC_weight
        patientScoreCalculator.addCalculation("alterationInMentalState", workflow.rawData['alterationInMentalState'] === true && workflow.rawData['lossOfConsciousness'] === true);
        // Scoring criteria 5 : symptom experience_weight
        patientScoreCalculator.addCalculation("feelingBetterSameWorseSinceInjury", workflow.rawData['feelingBetterSameWorseSinceInjury']);
        // Scoring criteria 6 : prior TBI_weight
        if(workflow.rawData['previousConcussionOrBrainInjury'] === true) {
            patientScoreCalculator.addCalculation("numberOfPreviousConcussionsOrBrainInjuries", workflow.rawData['numberOfPreviousConcussionsOrBrainInjuries']);
        }
        // Scoring criteria 7 : prolonged recovery_weight
        if(workflow.rawData['wasRecoveryProtractedOrProblematic'] === true) {
            patientScoreCalculator.addCalculation("protractedRecoveryDurationInMonths", workflow.rawData['protractedRecoveryDurationInMonths']);
        }
        // Scoring criteria 8 : MH disorder_weight
        patientScoreCalculator.addCalculation("mentalHealthDifficulties", workflow.rawData['mentalHealthDifficulties']);
        // Scoring criteria 9 : migraine_weight
        patientScoreCalculator.addCalculation("historyOfMigraine", workflow.rawData['historyOfMigraine']);
        
        var isDayPostInjury = false;
        let injuryDateString = workflow.rawData['injuryOccuranceDateTime'];  

        if (injuryDateString != undefined && injuryDateString != null) {
            let injuryDate = new Date(injuryDateString);
            let now = new Date();
            let msBetweenDates = Math.abs(injuryDate.getTime() - now.getTime());
            let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
            isDayPostInjury = hoursBetweenDates > 24;
        }

        let physicalValues = [
            workflow.rawData['headacheBistRating'],
            workflow.rawData['neckHurtsBistRating'],
            workflow.rawData['brightLightsBistRating'],
            workflow.rawData['loudNoisesBistRating'],
        ];

        let vestibValues = [
            workflow.rawData['dizzySickBistRating'],
            workflow.rawData['closedEyesAtSeaBistRating'],
            workflow.rawData['troubleWithVisionBistRating'],
            workflow.rawData['clumsyBistRating'],
        ];

        let cognitiveValues = [
            workflow.rawData['longerToThinkBistRating'],
            workflow.rawData['forgetThingsBistRating'],
            workflow.rawData['confusedEasilyBistRating'],
            workflow.rawData['troubleConcentratingBistRating']
        ];

        let post24Hours = [

        ];

        // 24 hours
        if (isDayPostInjury) {
            post24Hours.push(workflow.rawData['angryOrIrritatedBistRating']);
            post24Hours.push(workflow.rawData['dontFeelRightBistRating']);
            post24Hours.push(workflow.rawData['tiredDuringDayBistRating']);
            post24Hours.push(workflow.rawData['sleepMoreOrHardToSleepBistRating']);
        }

        let scaleValues = physicalValues.concat(vestibValues).concat(cognitiveValues).concat(post24Hours);

        if (!scaleValues.includes(null) && !scaleValues.includes.undefined) {
            let score = scaleValues.reduce((a, b) => a + b, 0);
            workflow.rawData['calculatedSymptomScore'] = score;

            let scoreCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, score, false, CalculationModel.CalculationType.bistScoreCalculation, new Date());
            redux.store.dispatch(saveCalculation(pathwayId, scoreCalculation, calculations));

            let physicalScore = physicalValues.reduce((a, b) => a + b, 0);
            let cognotiveScore = cognitiveValues.reduce((a, b) => a + b, 0);
            let vestibScore = vestibValues.reduce((a, b) => a + b, 0);

            let dominantClusters = [];
            if (vestibScore >= physicalScore && vestibScore >= cognotiveScore) {
                dominantClusters.push('Vestibularocular');
            }
            
            if (cognotiveScore >= vestibScore && cognotiveScore >= physicalScore) {
                dominantClusters.push('Cognitive');
            }
            
            if (physicalScore >= vestibScore && physicalScore >= cognotiveScore) {
                dominantClusters.push('Physical');
            }

            if (dominantClusters.length > 0) {
                let scoreCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, dominantClusters.join(', '), false, CalculationModel.CalculationType.bistDominantSymptomCluster, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, scoreCalculation, calculations));
                workflow.rawData['calculatedSymptomCluster'] = dominantClusters.join(', ');
            }
            else {
                let scoreCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, 'None', false, CalculationModel.CalculationType.bistDominantSymptomCluster, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, scoreCalculation, calculations));

                workflow.rawData['calculatedSymptomCluster'] = 'None';
            }

            const highScoreVestibular = vestibValues.some(value => value > 8);
            workflow.rawData['highScoreVestibular'] = highScoreVestibular;
            
            // Scoring criteria 10 : Dom VO_weight
            patientScoreCalculator.addCalculation("dominantVestibularCluster", (highScoreVestibular || dominantClusters.includes('Vestibularocular')));
            
            // Scoring criteria 11,12 : symptom score
            patientScoreCalculator.addCalculation("calculatedSymptomScore", score);
        }
        else {
            workflow.rawData['calculatedSymptomScore'] = null;
            workflow.rawData['calculatedSymptomCluster'] = null;
        }

        // Scoring criteria 13 : Impact_weight (> 24h from injury)
        if (isDayPostInjury) {
            patientScoreCalculator.addCalculation("overallImpactScore", workflow.rawData['overallImpactScore']);
        }

        const patientCalculatedScore = patientScoreCalculator.getScore();
        workflow.rawData['patientCalculatedScore'] = patientCalculatedScore;
        const displayPatientCalculatedScore = (patientCalculatedScore > 0) ?  patientCalculatedScore.toFixed(2) + "%" : "N/A";
        let patientScoreCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, displayPatientCalculatedScore, false, CalculationModel.CalculationType.bistMentalHealthScoreCalculation, new Date());
        redux.store.dispatch(saveCalculation(pathwayId, patientScoreCalculation, calculations));
    
        // Get latest index for AWPTA calculation
        let latestAWPTAIndex = "";
        if(workflow.rawData["awptaCheck5DateTime"]) {
            latestAWPTAIndex = "5";
        } else if (workflow.rawData["awptaCheck4DateTime"]) {
            latestAWPTAIndex = "4";
        } else if (workflow.rawData["awptaCheck3DateTime"]) {
            latestAWPTAIndex = "3";
        } else if (workflow.rawData["awptaCheck2DateTime"]) {
            latestAWPTAIndex = "2";
        } else if (workflow.rawData["awptaCheck1DateTime"]) {
            latestAWPTAIndex = "1";
        }

        // GCS/AWPTA
        if (formComplete) {

            let gcsValues = [workflow.rawData['eyeOpeningScore' + latestAWPTAIndex], workflow.rawData['motorScore' + latestAWPTAIndex], workflow.rawData['verbalScore' + latestAWPTAIndex]];

            if (!gcsValues.includes(null) && !gcsValues.includes(undefined) || workflow.rawData['neuroObsOkayCheck' + latestAWPTAIndex] == true) {
                var latestTotalGCS = this.getGCS(workflow, latestAWPTAIndex);

                let gcsCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, latestTotalGCS, false, CalculationModel.CalculationType.gcsCalculation, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, gcsCalculation, calculations));
                workflow.rawData['totalGCS'] = latestTotalGCS;

                // Calculate GCS for each A-WPTA Recall
                for (let i = 2; i <= 5; i++) {
                    const gcs = this.getGCS(workflow, i.toString());
                    workflow.rawData['totalGCS' + i.toString()] = gcs;
                }
    
                workflow.rawData['reducedGCSRedFlag'] = latestTotalGCS < 15;
    
                let riskCategory = 'N/A';
    
                //AWP-TA Check 1
                if (latestTotalGCS != null && latestTotalGCS != undefined) {
                    let result = this.getLatestAWPTA(workflow.rawData, latestTotalGCS);
                    let totalAWPTA = result.totalAWPTA;
                    let validForRiskCategory = result.validForRiskCategory;
        
                    //Risk category
                    if (validForRiskCategory) {

                        let awptaCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, totalAWPTA, false, CalculationModel.CalculationType.awptaScore, new Date());
                        redux.store.dispatch(saveCalculation(pathwayId, awptaCalculation, calculations));
                        workflow.rawData['totalAWPTA'] = totalAWPTA;

                        let score = workflow.rawData['calculatedSymptomScore'];
                        if (totalAWPTA < 18) {
                            riskCategory = 'High';
                        }
                        else if (score != null && score != undefined) {
                            if(isDayPostInjury) {
                                if (score > 65) {
                                    riskCategory = 'Moderate';
                                }
                                else {
                                    riskCategory = 'Low';
                                }
                            }
                            else {
                                if (score > 49) {
                                    riskCategory = 'Moderate';
                                }
                                else {
                                    riskCategory = 'Low';
                                }
                            }
                        }
                    }
                    else {
                        let awptaCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, null, false, CalculationModel.CalculationType.awptaScore, new Date());
                        redux.store.dispatch(saveCalculation(pathwayId, awptaCalculation, calculations));
                        workflow.rawData['totalAWPTA'] = null;
                    }
                }
    
                let riskCategoryCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, riskCategory, false, CalculationModel.CalculationType.concussionRiskCategory, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, riskCategoryCalculation, calculations));
                workflow.rawData['riskCategory'] = riskCategory;
            }
            else {
                let gcsCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, null, false, CalculationModel.CalculationType.gcsCalculation, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, gcsCalculation, calculations));

                workflow.rawData['totalGCS'] = null;
                workflow.rawData['reducedGCSRedFlag'] = null;

                let awptaCalculation = new CalculationModel(workflow.uuid, workflow.versionUuid, null, false, CalculationModel.CalculationType.awptaScore, new Date());
                redux.store.dispatch(saveCalculation(pathwayId, awptaCalculation, calculations));
                workflow.rawData['totalAWPTA'] = null;
                workflow.rawData['riskCategory'] = null;
            }

            // CT Scan
            var requiresCT;
            let ctDependentValues = [
                workflow.rawData['patientAge'],
                workflow.rawData['concussiveForce'],
                workflow.rawData['alterationInMentalState'],
                workflow.rawData['bleedingOrClottingDisorder'],
                workflow.rawData['dangerousMechanismOfOInjury'],
                workflow.rawData['totalGCS'],
                workflow.rawData['suspectedSkullFracture'],
                workflow.rawData['postTraumaticSeizure'],
                workflow.rawData['focalNeurologicalDeficit']
            ];

            if(workflow.rawData['totalGCS'] != null && workflow.rawData['totalGCS'] && workflow.rawData['totalGCS'] > 0 && workflow.rawData['totalGCS'] < 15) {
                requiresCT = true;
            }
            else if(workflow.rawData['suspectedSkullFracture'] == true) {
                requiresCT = true;
            }
            else if(workflow.rawData['postTraumaticSeizure'] == true) {
                requiresCT = true;
            }
            else if(workflow.rawData['focalNeurologicalDeficit'] == true) {
                requiresCT = true;
            }
            else if(workflow.rawData['vomiting'] == true) {
                requiresCT = true;
            }
            else if([
                workflow.rawData['clopidogrelOrTicagrelor'],
                workflow.rawData['warfarin'],
                workflow.rawData['revaroxabanOrDabigatran'],
                workflow.rawData['otherMedication']].includes(true)) {
                requiresCT = true;
            }
            else if (((workflow.rawData['concussiveForce'] == true || workflow.rawData['alterationInMentalState'] == true) && workflow.rawData['alterationInMentalState'] == true) && (workflow.rawData['patientAge'] >= 65 || workflow.rawData['bleedingOrClottingDisorder'] == true || (workflow.rawData['retrogradeAmnesiaDuration'] != null && workflow.rawData['retrogradeAmnesiaDuration'] != undefined && workflow.rawData['retrogradeAmnesiaDuration'] > 30) || workflow.rawData['dangerousMechanismOfOInjury'] == true)) {
                requiresCT = true;
            }
            else if(!ctDependentValues.includes(null) && !ctDependentValues.includes(undefined)) {
                requiresCT = false;
            }

            workflow.rawData['ctCriteraRedflag'] = requiresCT;
        }

        // High Risk Symptoms or Signs
        let hasHighRiskSymptomsOrSigns = null;
        let locDependentValues = [
            workflow.rawData['lossOfConsciousness'],
            workflow.rawData['lossOfConsciousnessDuration'],
            workflow.rawData['feelingBetterSameWorseSinceInjury'],
            workflow.rawData['arrivalGCS'],
            workflow.rawData['focalNeurologicalDeficit'],
            workflow.rawData['feelingBetterSameWorseSinceInjury'],
            workflow.rawData['neuroObsOkayCheck' + latestAWPTAIndex]
        ];

        if(!locDependentValues.includes(null) && !locDependentValues.includes(undefined)) {
            if ((workflow.rawData['lossOfConsciousness'] === true && workflow.rawData['lossOfConsciousnessDuration'] >= 30) || 
                workflow.rawData['feelingBetterSameWorseSinceInjury'] === "worseOption" || 
                workflow.rawData['arrivalGCS'] < 13 ||
                workflow.rawData['focalNeurologicalDeficit'] === true || 
                workflow.rawData['feelingBetterSameWorseSinceInjury'] === true ||
                workflow.rawData['neuroObsOkayCheck' + latestAWPTAIndex] === false ) {
                hasHighRiskSymptomsOrSigns = true;
            } else {
                hasHighRiskSymptomsOrSigns = false;
            }
        }

        workflow.rawData['highRiskSymptomsOrSignsRedFlag'] = hasHighRiskSymptomsOrSigns;
    }

    getGCS(workflow, index = "") {
        let motorScores = {
            'Obeys commands': 6,
            'Localises': 5,
            'Withdraws': 4,
            'Abnormal flexion': 3,
            'Extension': 2,
            'None': 1
        };

        let eyeScores = {
            'Spontaneously': 4,
            'To speech': 3,
            'To pain': 2,
            'None': 1
        };

        let verbalScores = {
            'Oriented': 5,
            'Confused': 4,
            'Inappropriate words': 3,
            'Incomprehensible sounds': 2,
            'None': 1
        };

        let totalGCS = 0;
        let eyeScoreRawValue = workflow.rawData['eyeOpeningScore' + index];
        if (eyeScoreRawValue != null & eyeScoreRawValue != undefined) {
            let eyeScore = eyeScores[eyeScoreRawValue];
            totalGCS += eyeScore;
        }

        let motorScoreRawValue = workflow.rawData['motorScore' + index];
        if (motorScoreRawValue != null & motorScoreRawValue != undefined) {
            let motorScore = motorScores[motorScoreRawValue];
            totalGCS += motorScore;
        }

        let verbalScoreRawValue = workflow.rawData['verbalScore' + index];
        if (verbalScoreRawValue != null & verbalScoreRawValue != undefined) {
            let verbalScore = verbalScores[verbalScoreRawValue];
            totalGCS += verbalScore;
        }

        if (totalGCS == 0) {
            totalGCS = null;
        }

        if (workflow.rawData['neuroObsOkayCheck' + index] == true && (totalGCS == 0 || totalGCS == null)) {
            totalGCS = 15;
        }

        return totalGCS;
    }

    getLatestAWPTA(rawData, totalGCS) {
        let checks = [];
        if(rawData["awptaCheck5DateTime"]) {
            checks = [{check: 'recollectionCheck5', recall: 'recollectionFailureCheck5'}];
        } else if (rawData["awptaCheck4DateTime"]) {
            checks = [{check: 'recollectionCheck4', recall: 'recollectionFailureCheck4'}];
        } else if (rawData["awptaCheck3DateTime"]) {
            checks = [{check: 'recollectionCheck3', recall: 'recollectionFailureCheck3'}];
        } else if (rawData["awptaCheck2DateTime"]) {
            checks = [{check: 'recollectionCheck2', recall: 'recollectionFailureCheck2'}];
        }
        
        const AWPTA = calcAWPTA(rawData, checks, totalGCS);
        const totalAWPTA = AWPTA.totalAWPTA;
        const validForRiskCategory = AWPTA.validForRiskCategory;

        return {totalAWPTA: totalAWPTA, 
            validForRiskCategory: validForRiskCategory};
    }
}