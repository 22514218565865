import wayfindAxios from "../components/Common/WayfindAxios";
import { ACC_API_ROOT } from "../config/config";

export interface BistQuestionnaireResponseParam {
  id: string | undefined;
  // Notes
  freeformNotes: string | undefined;
  //Research
  anonymousDataPermission: boolean | undefined;
  contactDetailsPermission: boolean | undefined;
  // Patient Details
  patientName: string | undefined;
  patientNHI: string | undefined;
  patientDOB: string | undefined;
  patientGender: string | undefined;
  patientSex: string | undefined;
  patientEthnicity: string | undefined;
  patientPhoneNumber: string | undefined;
  patientAddress: string | undefined;
  patientNOKName: string | undefined;
  patientNOKPhoneNumber: string | undefined;
  patientGPName: string | undefined;
  patientGPPractice: string | undefined;
  patientACCClaimNumber: string | undefined;

  // Injury Details
  injuryOccuranceDateTime: string | undefined;
  worstGCS: number | undefined;
  worstGCSDateTime: string | undefined;
  arrivalGCS: number | undefined;
  arrivalGCSDateTime: string | undefined;
  injuryEventDescription: string | undefined;
  dangerousMechanismOfOInjury: boolean | undefined;
  concussiveForce: boolean | undefined;
  directHeadInjury: boolean | undefined;
  otherInjuries: string | undefined;
  alterationInMentalState: boolean | undefined;
  lossOfConsciousness: boolean | undefined;
  lossOfConsciousnessDuration: number | undefined;
  retrogradeAmnesia: boolean | undefined;
  retrogradeAmnesiaDuration: number | undefined;
  alteredMentalState: boolean | undefined;
  postTraumaticSeizure: boolean | undefined;
  confoundingFactors: boolean | undefined;
  intoxicationOrDrugUse: boolean | undefined;
  psychologicalOrEmotionalTrauma: boolean | undefined;
  severePain: boolean | undefined;
  additionalInjuryDetails: string | undefined;

  // Clinical Assessment - Symptoms and Signs
  feelingBetterSameWorseSinceInjury: string | undefined;
  headache: boolean | undefined;
  vomiting: boolean | undefined;
  numberOfVomittingEpisodes: number | undefined;
  suspectedSkullFracture: boolean | undefined;
  depressedSkullFracture: boolean | undefined;
  openSkullFracture: boolean | undefined;
  basalSkullFracture: boolean | undefined;
  focalNeurologicalDeficit: boolean | undefined;
  focalNeurologicalDeficitDetails: string | undefined;
  additionalSymptomsAndSigns: string | undefined;

  // Clinical Assessment - Problems and Past Medical History
  previousConcussionOrBrainInjury: boolean | undefined;
  numberOfPreviousConcussionsOrBrainInjuries: number | undefined;
  dateOfLastInjury: number | undefined;
  wasRecoveryProtractedOrProblematic: boolean | undefined;
  protractedRecoveryDurationInMonths: number | undefined;
  bleedingOrClottingDisorder: boolean | undefined;
  historyOfMigraine: boolean | undefined;
  mentalHealthDifficulties: boolean | undefined;
  previousAlchoholOrSubstanceAbuse: boolean | undefined;
  cognitiveImpairment: boolean | undefined;
  additionalHistoryDetails: string | undefined;

  // Clinical Assessment - Medications
  anticoagulantOrAntiplateletAgent: boolean | undefined;
  aspirin: boolean | undefined;
  clopidogrelOrTicagrelor: boolean | undefined;
  warfarin: boolean | undefined;
  revaroxabanOrDabigatran: boolean | undefined;
  otherMedication: boolean | undefined;
  additionalMedications: string | undefined;

  // Clinical Assessment - Social History
  highRiskOccupation: boolean | undefined;
  employment: string | undefined;
  socialDetails: string | undefined;

  // A-WPTA
  awptaCheck1DateTime: string | undefined;

  // A-WPTA - Orientation
  whatIsYourNameCheck1: boolean | undefined;
  nameOfThisPlaceCheck1: boolean | undefined;
  whyAreYouHereCheck1: boolean | undefined;
  whatMonthAreWeInCheck1: boolean | undefined;
  whatYearAreWeInCheck1: boolean | undefined;

  // A-WPTA - Neurological Orientations
  neuroObsDateTime1: string | undefined;
  neuroObsOkayCheck1: boolean | undefined;
  eyeOpeningScore1: string | undefined;
  verbalScore1: string | undefined;
  motorScore1: string | undefined;
  leftPupilSize1: number | undefined;
  leftPupilReaction1: number | undefined;
  rightPupilSize1: number | undefined;
  rightPupilReaction1: string | undefined;

  pictureCardTestRecollection: boolean | undefined;

  // A-WPTA Recall 1
  awptaCheckDateTime2: string | undefined;
  whatIsYourNameCheck2: boolean | undefined;
  nameOfThisPlaceCheck2: boolean | undefined;
  whyAreYouHereCheck2: boolean | undefined;
  whatMonthAreWeInCheck2: boolean | undefined;
  whatYearAreWeInCheck2: boolean | undefined;
  neuroObsDateTime2: string | undefined;
  neuroObsOkayCheck2: boolean | undefined;
  eyeOpeningScore2: string | undefined;
  verbalScore2: string | undefined;
  motorScore2: string | undefined;
  leftPupilSize2: number | undefined;
  leftPupilReaction2: string | undefined;
  rightPupilSize2: number | undefined;
  rightPupilReaction2: string | undefined;
  recollectionCheck2: string[] | undefined;
  recollectionFailureCheck2: string[] | undefined;

  // A-WPTA Recall 2
  awptaCheckDateTime3: string | undefined;
  whatIsYourNameCheck3: boolean | undefined;
  nameOfThisPlaceCheck3: boolean | undefined;
  whyAreYouHereCheck3: boolean | undefined;
  whatMonthAreWeInCheck3: boolean | undefined;
  whatYearAreWeInCheck3: boolean | undefined;
  neuroObsDateTime3: string | undefined;
  neuroObsOkayCheck3: boolean | undefined;
  eyeOpeningScore3: string | undefined;
  verbalScore3: string | undefined;
  motorScore3: string | undefined;
  leftPupilSize3: number | undefined;
  leftPupilReaction3: string | undefined;
  rightPupilSize3: number | undefined;
  rightPupilReaction3: string | undefined;
  recollectionCheck3: string[] | undefined;
  recollectionFailureCheck3: string[] | undefined;

  // A-WPTA Recall 3
  awptaCheckDateTime4: string | undefined;
  whatIsYourNameCheck4: boolean | undefined;
  nameOfThisPlaceCheck4: boolean | undefined;
  whyAreYouHereCheck4: boolean | undefined;
  whatMonthAreWeInCheck4: boolean | undefined;
  whatYearAreWeInCheck4: boolean | undefined;
  neuroObsDateTime4: string | undefined;
  neuroObsOkayCheck4: boolean | undefined;
  eyeOpeningScore4: string | undefined;
  verbalScore4: string | undefined;
  motorScore4: string | undefined;
  leftPupilSize4: number | undefined;
  leftPupilReaction4: string | undefined;
  rightPupilSize4: number | undefined;
  rightPupilReaction4: string | undefined;
  recollectionCheck4: string[] | undefined;
  recollectionFailureCheck4: string[] | undefined;

  // A-WPTA Recall 4
  awptaCheckDateTime5: string | undefined;
  whatIsYourNameCheck5: boolean | undefined;
  nameOfThisPlaceCheck5: boolean | undefined;
  whyAreYouHereCheck5: boolean | undefined;
  whatMonthAreWeInCheck5: boolean | undefined;
  whatYearAreWeInCheck5: boolean | undefined;
  neuroObsDateTime5: string | undefined;
  neuroObsOkayCheck5: boolean | undefined;
  eyeOpeningScore5: string | undefined;
  verbalScore5: string | undefined;
  motorScore5: string | undefined;
  leftPupilSize5: number | undefined;
  leftPupilReaction5: string | undefined;
  rightPupilSize5: number | undefined;
  rightPupilReaction5: string | undefined;
  recollectionCheck5: string[] | undefined;
  recollectionFailureCheck5: string[] | undefined;

  // BIST Symptom Scale
  bistDateTime: string | undefined;
  headacheBistRating: number | undefined;
  neckHurtsBistRating: number | undefined;
  brightLightsBistRating: number | undefined;
  loudNoisesBistRating: number | undefined;
  dizzySickBistRating: number | undefined;
  closedEyesAtSeaBistRating: number | undefined;
  troubleWithVisionBistRating: number | undefined;
  clumsyBistRating: number | undefined;
  longerToThinkBistRating: number | undefined;
  forgetThingsBistRating: number | undefined;
  confusedEasilyBistRating: number | undefined;
  troubleConcentratingBistRating: number | undefined;
  angryOrIrritatedBistRating: number | undefined;
  dontFeelRightBistRating: number | undefined;
  tiredDuringDayBistRating: number | undefined;
  sleepMoreOrHardToSleepBistRating: number | undefined;
  overallImpactScore: number | undefined;

  // Investigation - Neurological Observations
  neuroObsDateTime: string | undefined;
  neuroObsOkayCheck: boolean | undefined;
  eyeOpeningScore: string | undefined;
  verbalScore: string | undefined;
  motorScore: string | undefined;
  leftPupilSize: number | undefined;
  leftPupilReaction: string | undefined;
  rightPupilSize: number | undefined;
  rightPupilReaction: string | undefined;

  // Investigation - Vital Signs
  vitalSignsDateTime: string | undefined;
  normalVitalSignsCheck: boolean | undefined;
  heartRate: number | undefined;
  bloodPressureHigh: number | undefined;
  bloodPressureLow: number | undefined;
  bodyTemperature: number | undefined;
  oxygenSaturation: number | undefined;
  respiratoryRate: number | undefined;
  supplementalOxygen: boolean | undefined;
  levelOfConsciousness: string | undefined;

  // Investigation - CT Brain
  hasCTBeenPerformed: boolean | undefined;
  ctDateTime: string | undefined;
  isCTScanNormal: boolean | undefined;
  ctScanFindings: string | undefined;
}

const storeQuestionnaireResponse = async (
  data: BistQuestionnaireResponseParam,
  questionnaireType: string,
  patientName: string,
  patientNumber: string,
  resourceId: string | undefined,
  completed: boolean = false
) => {
  const params = new URLSearchParams();
  params.append("patientName", patientName);
  params.append("patientNumber", patientNumber);
  params.append("completed", `${completed}`);
  params.append("questionnaireType", questionnaireType);
  if (resourceId) {
    // Update existing Questionnaire Response
    params.append("resourceId", resourceId);
  }

  // Set API's URL for the current environment
  wayfindAxios.defaults.baseURL = ACC_API_ROOT;

  const response = await wayfindAxios.post(
    `api/SharedCare/StoreQuestionnaireResponse?${params.toString()}`,
    data
  );

  // Return id of the created/updated resource
  return response.data;
};

export default storeQuestionnaireResponse;
