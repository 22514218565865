import React, { useEffect, useState } from "react";
import { Box, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import moment from "moment";
import { FormContentView } from "../../FormsUI/FormsUI Components/Containers/FormContentView";
import { FormsStyles } from "../../FormsUI/FormsUI";
import { Patient } from "../../../api/getPatientByNHINumber";
import getPatientByNHINumber from "../../../api/getPatientByNHINumber";
import getQuestionnaireResponse from "../../../api/getQuestionnaireResponse";
import { getPathwayIdFromURL } from "../../../helpers/URLHelper";
import { useFHIRStoreContext } from "../../../components/pages/FormRouteHandler";

interface NHINumberFormDetailProp {
  formDetail: any;
  errorLabels: string[];
  hasError: boolean;
}

const NHINumberFormDetail: React.FC<NHINumberFormDetailProp> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setFormDataFromPathwayData } = useFHIRStoreContext();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [textNHINumber, setTextNHINumber] = useState<string>(props.formDetail.editingValue ?? "");
  const [calledNHINumber, setCalledNHINumber] = useState<string>();
  const [showClearIcon, setShowClearIcon] = useState<string>("none");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setShowClearIcon(textNHINumber === "" ? "none" : "flex");
  }, [textNHINumber]);

  const textFieldUpdated = (value: string) => {
    const updatedText = value.toUpperCase();
    props.formDetail.form.valueUpdated(props.formDetail.identifier, updatedText);
    setTextNHINumber(updatedText);
  };

  const clearNHINumber = () => {
    textFieldUpdated("");
    updateNHIRelatedFields(undefined);
    enqueueSnackbar(
      "The patient information has been cleared. Please re-enter the NHI number to populate the fields again.",
      {
        variant: "success",
      }
    );
  };

  const lookupNHINumber = async () => {
    if (loading) return;
    if (!textNHINumber || textNHINumber === "") {
      enqueueSnackbar("Please enter an NHI Number", {
        variant: "warning",
      });
      return;
    }

    const pathwayId = getPathwayIdFromURL();
    const isBISTPathway = pathwayId === "accbist01";

    setLoading(true);

    if (isBISTPathway) {
      try {
        // Check if in-progress QuestionnaireResponse for the NHI number exists
        const formData = await getQuestionnaireResponse("BrainInjury", textNHINumber);
        if (formData) {
          // Restore in-progress form data
          setCalledNHINumber(textNHINumber);
          setFormDataFromPathwayData(pathwayId, formData);

          enqueueSnackbar(
            "In-progress form data for this patient has been found on the FHIR server and restored to the forms.",
            {
              variant: "success",
            }
          );

          // Finish without look up NHI number
          setLoading(false);
          return;
        }
      } catch (error) {
        // Logging and continue looking up the NHI number when failed the questionnaire search
        console.log("Failed to retrieve QuestionnaireResponse, but continuing the process.");
        console.log(error);
      }
    }

    try {
      // Call API to get the Patient info using NHI Number
      const data = await getPatientByNHINumber(textNHINumber);
      if (data?.DeceasedDate && data?.DeceasedDate.length > 0) {
        updateNHIRelatedFields(undefined);
        enqueueSnackbar(
          "The patient associated to this NHI number is deceased, please check and re-enter the NHI number for the patient you are treating",
          {
            variant: "warning",
          }
        );
      } else {
        updateNHIRelatedFields(data);
        enqueueSnackbar(
          "The Patient information has been populated based on the NHI number provided. Please validate this information with the patient.",
          {
            variant: "success",
          }
        );
      }
    } catch {
      updateNHIRelatedFields(undefined);
      enqueueSnackbar("Failed to retrieve the Patient information", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const updateNHIRelatedFields = (data: Patient | undefined) => {
    var nhiNumberUpdated = (data === undefined) || (data.NHINumber !== calledNHINumber);
    setCalledNHINumber(data?.NHINumber);

    // Update field values with NHI Lookup response
    let patientName = "";
    if (data) {
      patientName = data.GivenNames?.join(" ") ?? "";
      if (patientName.length > 0) patientName += " ";
      patientName += data.FamilyName;
    }
    props.formDetail.form.valueUpdated("patientName", patientName);
    var nameOptions: string[] = [patientName];
    data?.NameOptions?.forEach((option) => {
        var name = option.GivenNames?.join(" ") ?? "";
        if (name.length > 0) name += " ";
        name += option.FamilyName;
        nameOptions.push(name);
      });
    props.formDetail.form.valueUpdated("patientNameOptions", nameOptions.join(","));
    
    if (data?.Gender === "male") {
      props.formDetail.form.valueUpdated("patientSex", "genderMaleOption");
      props.formDetail.form.valueUpdated("patientGender", "Male");
    } else if (data?.Gender === "female") {
      props.formDetail.form.valueUpdated("patientSex", "genderFemaleOption");
      props.formDetail.form.valueUpdated("patientGender", "Female");
    } else {
      props.formDetail.form.valueUpdated("patientSex", "otherOption");
      props.formDetail.form.valueUpdated("patientGender", data?.Gender ?? "");
    }

    if (data?.BirthDate) {
      let dateValue = moment(data?.BirthDate, "DD/MM/YYYY");
      props.formDetail.form.valueUpdated("patientDOB", dateValue.toDate());
      props.formDetail.form.valueUpdated("patientAge", moment().diff(dateValue, "years"));
    } else {
      props.formDetail.form.valueUpdated("patientDOB", undefined);
      props.formDetail.form.valueUpdated("patientAge", undefined);
    }
    props.formDetail.form.valueUpdated("patientAddress", data?.Address ?? "");
    props.formDetail.form.valueUpdated("patientEthnicity", data?.Ethnicity ?? "");

    if (nhiNumberUpdated) {
      // Clear other fields in Patient Detail page
      props.formDetail.form.valueUpdated("patientACCClaimNumber", undefined);
      props.formDetail.form.valueUpdated("patientGPName", undefined);
      props.formDetail.form.valueUpdated("patientGPPractice", undefined);
      props.formDetail.form.valueUpdated("patientPhoneNumber", undefined);
      props.formDetail.form.valueUpdated("patientNOKName", undefined);
      props.formDetail.form.valueUpdated("patientNOKPhoneNumber", undefined);
    }
  };

  const createTextField = () => {
    const textField = (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search"
          sx={{
            width: isSmallScreen ? "none" : "15em",
            color: FormsStyles.primaryTint,
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              border: "2px solid " + FormsStyles.backgroundColor,
              "& :focus": { color: "black" },
              color: textNHINumber.length > 0 ? FormsStyles.primaryTint : FormsStyles.grey,
            },
            "& .MuiInputBase-input": {
              py: 1,
            },
            "& fieldset": { border: "none" },
          }}
          inputProps={{ style: { textAlign: "right" } }}
          value={textNHINumber}
          onChange={(event) => {
            textFieldUpdated(event.target.value);
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              lookupNHINumber();
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end" style={{ display: showClearIcon }}>
                  <IconButton
                    size="small"
                    disabled={loading}
                    sx={{ p: 0, color: "silver" }}
                    onClick={() => clearNHINumber()}
                  >
                    <ClearIcon sx={{ width: "18px" }} />
                  </IconButton>
                </InputAdornment>
                <InputAdornment position="end">
                  <LoadingButton
                    size="small"
                    sx={{ p: 0, minWidth: 0, color: FormsStyles.primaryTint }}
                    loading={loading}
                    onClick={() => lookupNHINumber()}
                  >
                    <SearchIcon />
                  </LoadingButton>
                </InputAdornment>
              </>
            ),
          }}
        />
      </Box>
    );

    return textField;
  };

  return (
    <FormContentView
      identifier={props.formDetail.identifier}
      isSubcell={false}
      titleString={props.formDetail.title}
      validation={props.errorLabels}
      isMandatory={props.formDetail.form.isMandatory(props.formDetail.identifier)}
      detailString={props.formDetail.detail}
      rightControl={createTextField()}
      subcell={undefined}
      subCellbackgroundColor={FormsStyles.backgroundColor}
      showHover={true}
    />
  );
};

export default NHINumberFormDetail;
