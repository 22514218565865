import { FormWithRedux } from '../../FormWithRedux';
import { DateOfBirth } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DateTimeValidation';
import moment from 'moment';
import { DateHelper } from '../../../waferJS/Helpers/DateHelper';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationIdentifier } from '../../../waferJS/Form/Validation/FormValidationIdentifier';

export class ACCBISTPPatientDetailsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('patientDetailsSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);

        this.ageValidator = new FormValidation(FormValidationIdentifier.patientDOB, function (value) {
            if (value === null || value === undefined) {
                return [FormValidationError.RequiredError];
            }

            const dob = new Date(value);
            const today = new Date();
            
            let age = today.getFullYear() - dob.getFullYear();
            const monthDiff = today.getMonth() - dob.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
                age--;
            }
            
            if (age < 8) {
                return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Patient must be at least 8 years old')];
            }
            if (age > 16) {
                return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Patient must not be older than 16 years')]
            }
            
            if (isNaN(dob.getTime())) {
                return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Please enter a valid date')]
            }
            
            if (dob > today) {
                return [new FormValidationError(FormValidationError.FormErrorType.warning, 'DOB cannot be in the future')]
            }
            
            return [];
        });
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if(identifier == 'patientDOB') {
            return true; //this.getValue(identifier) != null || this.getValue('patientAge') == null;
        }
        else if (identifier == 'patientAgeWarning') {
            let age = this.getValue('patientAge');
            return age != null && age != undefined && age < 8 || age > 16;
        }

        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'patientAgeWarning') {
            return false;
        }
        else if (identifier == 'patientDOB') {
            return true; //this.shouldDisplayFormDetailForIdentifier(identifier);
        }
        else if (identifier == 'patientName' 
        || identifier == 'patientAge'
        || identifier == 'patientNHI'
        || identifier == 'patientACCClaimNumber'
        || identifier == 'patientPhoneNumber') {
            return true;
        }

        return false;
    }

    getValueType(identifier) {
        if (identifier == 'patientDOB') {
            return 'date';
        } else if (identifier == 'patientSex' 
        || identifier == 'patientName' 
        || identifier == 'patientNHI' 
        || identifier == 'patientGender' 
        || identifier == 'patientEthnicity'
        || identifier == 'patientAddress'
        || identifier == 'patientNOKName'
        || identifier == 'patientGPName'
        || identifier == 'patientGPPractice'
        || identifier == 'patientNOKPhoneNumber'
        || identifier == 'patientPhoneNumber'
        || identifier == 'patientACCClaimNumber') {
            return 'string';
        } else if(identifier == 'patientAge') {
            return 'double';
        }

        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        if (identifier === 'patientDOB') {
            return [DateOfBirth, this.ageValidator];
        }
        return [];
    }


    detailForIdentifier(prefix, value) {
        if (value.identifier == 'patientAge' && this.getValue('patientAge') != null){
            if (value.currentValue != null) {
                return 'Age: ' + this.getValue('patientAge') + ' year' + (this.getValue('patientAge') == 1 ? '' : 's');
            }
        }
        else if (value.identifier == 'patientDOB' && this.getValue('patientDOB') != null) {
            let dateValue = moment(this.getValue('patientDOB'));
            return value.title + ': ' + DateHelper.getDateAsFormat(dateValue.toDate(), 'MM-DD-YYYY');
        }
        else {
            return super.detailForIdentifier(prefix, value);
        }
    }
}