import { FormWithRedux } from '../../FormWithRedux';

 
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormsStyles, FormsUIGlossary } from '../../../waferJS/FormsUI/FormsUI';
import { ACCBISTPGCSForm } from './ACCBISTP-GCSForm';
import { ACCBISTPPupilAssessmentForm } from './ACCBISTP-PupilAssessmentForm';

export class ACCBISTPNeuroObsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater, index) {
        super('gcsSubform' + index, formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
        this.index = index;
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'pupilAssessmentSubform' + this.index:
                return new ACCBISTPPupilAssessmentForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater, this.index);
            case 'gcsSectionSubform' + this.index:
                return new ACCBISTPGCSForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater, this.index);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'neuroObsOkayCheck' + this.index) {
            return this.getValue('neuroObsOkayCheck' + this.index) == true;
        }

        let allFineCheck = this.getValue('neuroObsOkayCheck' + this.index);
        
        if (allFineCheck != null && allFineCheck != undefined && allFineCheck == true) {
            return false;
        } 
        
        return true;
    }

    getValueType(identifier) {
        if (identifier == 'neuroObsOkayCheck' + this.index) {
            return 'boolean';
        }
        else if (identifier == 'neuroObsDateTime' + this.index) {
            return 'date';
        }
        
        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        if (identifier == 'neuroObsOkayCheck' + this.index) {
            let self = this;
            let gcsNot15 = new FormValidation('AbnormalitiesPresentConflict', function () {
            
                let totalGCS = self.getValue('totalGCS' + self.index);
                let noIssuesCheck = self.getValue('neuroObsOkayCheck' + self.index);

                if(totalGCS != null && totalGCS != undefined && totalGCS > 0 && totalGCS != 15) {
                    if (noIssuesCheck == true) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'GCS is not 15')];
                    }
                    else {
                        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'GCS is not 15')];
                    }
                }

                return [];
            });
    
            let valueConflict = new FormValidation('AbnormalitiesPresentConflict', function () {
                let noIssuesCheck = self.getValue('neuroObsOkayCheck' + self.index);

                let leftEyeSize = self.getValue('leftPupilSize' + self.index);
                let leftEyeState = self.getValue('leftPupilReaction' + self.index);
                let rightEyeSize = self.getValue('rightPupilSize' + self.index);
                let rightEyeState = self.getValue('rightPupilReaction' + self.index);

                if (noIssuesCheck == true && leftEyeSize != null & leftEyeSize != undefined && leftEyeSize != null & leftEyeSize != undefined && leftEyeSize != rightEyeSize) {
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Pupils are not symmetrical')];
                }
                else if (noIssuesCheck == true && leftEyeState != null & leftEyeState != undefined && rightEyeState != null & rightEyeState != undefined && (leftEyeState != 'Reacts briskly' || rightEyeState != 'Reacts briskly')) {
                    return [new FormValidationError(FormValidationError.FormErrorType.error, 'Pupil reaction is not normal')];
                }
                else {
                    return [];
                }
            });

            return [gcsNot15, valueConflict];
        }

        return [];
    }

    getBadgeValue(identifier) {
        if (identifier == 'totalGCSScore' + this.index) {
            let value = this.getValue('totalGCS' + this.index);
            
            if (value != null && value != undefined) {
                return [{
                    color: FormsStyles.black,
                    title: 'Total GCS',
                    value: value.toString(),
                    solid: false
                }];
            }
            else {
                return [];
            }
        }

        return [];
    }
}