import React from "react";
import { DSRouter } from "./DSRouter";
import { FormLoader } from "../../forms/FormLoader";
import { useUserInfoContext } from "../Hooks/UserInfoProvider";

interface DSRouterWrapperProp {
  formLoader: FormLoader;
}

const DSRouterWrapper: React.FC<DSRouterWrapperProp> = ({ formLoader }) => {
  const { instance, userInfo } = useUserInfoContext();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <DSRouter
      formLoader={formLoader}
      userName={userInfo?.Name}
      userHPINumber={userInfo?.HPINumber}
      userEmail={userInfo?.Email}
      logout={() => handleLogout()}
    />
  );
};

export default DSRouterWrapper;
