import { EDChestPainFormatter } from './edChestPain01/EDChestPain-Formatter';
import { GPChestPainFormatter } from './gpChestPain01/GPChestPain-Formatter';
import { GallbladderFormatter } from './Gallbladder01/GallbladderFormatter';
import moment from 'moment';
import { OsteoporosisFormatter } from './dxaScan01/Osteoporosis-Formatter';
import { ACCMRIFormatter } from './accmri01/ACCMRI-Formatter';
import { ACCBISTFormatter } from './concussion01/ACCBIST-Formatter';
import { ACCBISTPFormatter } from './accbist02/ACCBISTP-Formatter';

export class PathwayFormatter {
    static formatAsPlainText(formLoader, pathwayId, workflow, actions){
        switch(pathwayId){
            case 'chestPain01':
                return EDChestPainFormatter.formatAsPlainText(formLoader, workflow, actions);
            case 'gallbladder01':
                return GallbladderFormatter.formatAsPlainText(formLoader, workflow, actions);
            case 'dxaScan01':
                return OsteoporosisFormatter.formatAsPlainText(formLoader, workflow, actions);
            case 'gpChestPain01':
                return GPChestPainFormatter.formatAsPlainText(formLoader, workflow, actions);
            case 'accmri01':
                return ACCMRIFormatter.formatAsPlainText(formLoader, workflow, actions);
            case 'accbist01':
                return ACCBISTFormatter.formatAsPlainText(formLoader, workflow, actions);
            case 'accbist02':
                return ACCBISTPFormatter.formatAsPlainText(formLoader, workflow, actions);
            case 'QuidelOrtho':
                return EDChestPainFormatter.formatAsPlainText(formLoader, workflow, actions);
            case 'QuidelOrtho2':
                return EDChestPainFormatter.formatAsPlainText(formLoader, workflow, actions);
            default: 
                return '';
        }
    }

    static formatDate(date, format) {
        if (date == null) { return null; }
        return moment(date).format(format);
    }
}