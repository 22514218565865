// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { FormsStyles } from '../../FormsUI';
import '../../Styles/textfield.scss';
//import $ from 'jquery';
//import jqueryMaskPlugin from 'jquery-mask-plugin'; // eslint-disable-line no-unused-vars

// COMPONENT
export class FormTextField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value == null ? undefined : props.value,
            placeholder: props.placeholder,
            unitText: props.unitText,
            shouldShowUnit: props.value == null ? false : true,
            alignment: props.alignment == null ? 'right' : props.alignment,
            text: null,
            focused: false,
            inputType: this.props.inputType == null ? 'text' : this.props.inputType,
            shouldUpdateParent: false,
            spellCheck: this.props.spellCheck == null ? true : false,
        };

        this.textInput = React.createRef();

        this.highlight = this.highlight.bind(this);
        this.unhighlight = this.unhighlight.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
        this.typingTimeout = null;
        this.updateParent = this.updateParent.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.placeholder != prevProps.placeholder) {
            this.setState({ placeholder: this.props.placeholder });
        }

        if(this.props.unitText != prevProps.unitText){
            this.setState({unitText: this.props.unitText});
        }

        if (this.props.value != prevProps.value) {
            this.setState({ value: this.props.value });
        }

        if(this.props.shouldDelayResponse != false) {
            if(this.state.shouldUpdateParent == true && prevState.shouldUpdateParent == false){
                this.updateParent();
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.placeholder !== this.props.placeholder){
            return true;
        }

        if(nextState.placeholder !== this.state.placeholder){
            return true;
        }

        if(nextProps.onChange !== this.props.onChange){
            return true;
        }

        if(nextState.onChange !== this.state.onChange){
            return true;
        }

        if(nextProps.value !== this.props.value){
            return true;
        }

        if(nextState.value !== this.state.value){
            return true;
        }

        if(nextProps.unitText !== this.props.unitText){
            return true;
        }

        if(nextState.unitText !== this.state.unitText){
            return true;
        }

        if(nextProps.onFocus !== this.props.onFocus){
            return true;
        }

        if(nextState.onFocus !== this.state.onFocus){
            return true;
        }

        if(nextProps.onBlur !== this.props.onBlur){
            return true;
        }

        if(nextState.onBlur !== this.state.onBlur){
            return true;
        }

        if(nextProps.width !== this.props.width){
            return true;
        }

        if(nextState.width !== this.state.width){
            return true;
        }

        if(nextProps.onKeyPress !== this.props.onKeyPress){
            return true;
        }

        if(nextState.onKeyPress !== this.state.onKeyPress){
            return true;
        }

        if(nextProps.alignment !== this.props.alignment){
            return true;
        }

        if(nextState.alignment !== this.state.alignment){
            return true;
        }

        if(nextProps.inputType !== this.props.inputType){
            return true;
        }

        if(nextState.inputType !== this.state.inputType){
            return true;
        }

        if(nextProps.mask !== this.props.mask){
            return true;
        }

        if(nextState.mask !== this.state.mask){
            return true;
        }

        if(nextProps.color !== this.props.color){
            return true;
        }

        if(nextState.color !== this.state.color){
            return true;
        }

        if(nextState.focused !== this.state.focused){
            return true;
        }

        if(nextState.spellCheck !== this.state.spellCheck){
            return true;
        }

        return false;
    }


    valueChanged(value) {
        this.setState({ 
            value: value.target.value
        });

        if(this.props.shouldDelayResponse != false){
            clearTimeout(this.typingTimeout);
            this.typingTimeout = setTimeout(this.updateParent, 1000);
        } else {
            clearTimeout(this.typingTimeout);
            this.typingTimeout = setTimeout(this.updateParent, 0);
        }
        
    }

    updateParent() {
        let returningValue = this.state.value == '' ? null : this.state.value;
        this.props.onChange(returningValue);
        this.setState({shouldUpdateParent: false});
    }

    highlight() {
        this.setState({ 
            shouldShowUnit: true,
            focused: true
        });
        if (this.props.onFocus != null) {
            this.props.onFocus();
        }
    }

    unhighlight() {
        clearTimeout(this.typingTimeout);
        this.setState({shouldUpdateParent: true});

        this.setState({
            focused: false
        });

        if (this.props.value == undefined) {
            this.setState({ shouldShowUnit: false });
        }
        if (this.props.onBlur != null) {
            this.props.onBlur(this.state.value);
        }

        if(this.state.value != null && this.state.value != undefined ){
            // window.gtag('event', 'entered-text-input', {
            //     event_category: 'action',
            //     event_label: 'entered-text-input'
            // });
        }
    }

    onKeyPress(event) {
        if (event != undefined && event.key === 'Enter') {
            event.target.blur();
            this.unhighlight();
        }

        if(this.props.onKeyPress != null){
            this.props.onKeyPress(event);
        }
    }

    render() {
        let textFieldContainerStyle = {
            width: this.props.width != null ? this.props.width : null,
            borderColor: FormsStyles.backgroundColor
        };

        let color = this.props.color; 
        if(this.props.color == null){
            color = !this.state.focused ? FormsStyles.primaryTint : FormsStyles.black;
        }

        let textFieldstyle = {
            height: '2.5em',
            width: '100%',
            fontSize: '1em',
            paddingLeft: '0.2em',
            paddingRight: '0.2em',
            textAlign: this.state.alignment,
            borderRadius: '10px',
            borderStyle: 'none',
            marginRight: this.state.unitText != null ? '0.1em' : '0',
            color: color
        };

        let unitContainer = {
            marginTop: '0.4em',
            textAlign: 'right'
        };

        let unitStyle = {
            color: 'rgba(76, 125, 175, 0.32)',
            width: this.state.unitText == null ? '0' : 'auto',
            textAlign: 'right',
            marginBottom: '0.4em'
        };

        return (
            <div className={(this.props.longText ? 'form-long-text-field ' : 'form-text-field ') + (this.state.value == null || this.state.value == '' ? 'is-empty' : '')} style={textFieldContainerStyle}>
                <input className="form-input" ref={this.textInput} type={this.state.inputType} spellCheck={this.state.spellCheck} pattern={this.props.inputType == 'number' ? '[0-9]*' : ''} onKeyPress={this.onKeyPress} autoComplete={'off'} style={textFieldstyle} placeholder={this.state.focused ? '' : this.state.placeholder} onChange={this.valueChanged} value={this.state.value || ''} onFocus={() => this.highlight()} onBlur={() => this.unhighlight()} />
                {this.state.unitText && (this.state.focused || (this.state.value != null && this.state.value != '')) &&
                <div style={unitContainer}>
                    <div style={unitStyle}>{this.state.unitText}</div>
                </div>}
            </div>
        );
    }
}

FormTextField.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    unitText: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    width: PropTypes.string,
    onKeyPress: PropTypes.func,
    alignment: PropTypes.string,
    inputType: PropTypes.string,
    mask: PropTypes.string,
    color: PropTypes.string,
    shouldDelayResponse: PropTypes.bool,
    spellCheck: PropTypes.bool,
    longText: PropTypes.bool,
};