import { FormWithRedux } from "../../FormWithRedux";
import { ACCBISTPActions } from "../actions/ACCBISTP-Actions";
import { ACCBISTPCalculations } from "../calculations/ACCBISTP-Calculations";
import { ACCBISTPDependantValuesUpdater } from "../calculations/ACCBISTP-DependantValuesUpdater";
import { ACCBISTPAWPTAForm } from "./ACCBISTP-AWPTAForm";
import { ACCBISTPAWPTA2Form } from "./ACCBISTP-AWPTAForm2";
import { ACCBISTPAWPTA3Form } from "./ACCBISTP-AWPTAForm3";
import { ACCBISTPAWPTA4Form } from "./ACCBISTP-AWPTAForm4";
import { ACCBISTPAWPTA5Form } from "./ACCBISTP-AWPTAForm5";
import { ACCBISTPClinicalAssessmentForm } from "./ACCBISTP-ClinicalAssessmentForm";
import { ACCBISTPCTBrainForm } from "./ACCBISTP-CTBrainForm";
import { ACCBISTPInjuryDetailsForm } from "./ACCBISTP-InjuryDetailsForm";
import { ACCBISTPInformationForm } from "./ACCBISTP-IntroForm";
import { ACCBISTPNeuroObsForm } from "./ACCBISTP-NeuroObsForm";
import { ACCBISTPNotesForm } from "./ACCBISTP-NotesForm";
import { ACCBISTPPatientDetailsForm } from "./ACCBISTP-PatientDetails";
import { ACCBISTPResearchForm } from "./ACCBISTP-ResearchForm";
import { ACCBISTPResultsForm } from "./ACCBISTP-ResultsForm";
import { ACCBISTPSymptonScaleForm } from "./ACCBISTP-SymptomScaleForm";
import { ACCBISTPVitalSignsForm } from "./ACCBISTP-VitalSignsForm";

export class ACCBISTPMainForm extends FormWithRedux {
  constructor(formLoader) {
    super('mainForm', formLoader, null, new ACCBISTPActions(), new ACCBISTPDependantValuesUpdater(formLoader), new ACCBISTPCalculations());
  }

  generateSubform(identifier) {
    switch (identifier) {
      case 'introForm':
        return new ACCBISTPInformationForm(this.formLoader);
      case 'notesForm':
        return new ACCBISTPNotesForm(this.formLoader, this.dependentValuesUpdater);
      case 'researchForm':
        return new ACCBISTPResearchForm(this.formLoader, this, this.dependentValuesUpdater);
      case 'resultsForm':
        return new ACCBISTPResultsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        case 'patientDetailsSubform':
          return new ACCBISTPPatientDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
      case 'injuryDetailsSubform':
          return new ACCBISTPInjuryDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
      case 'clinicalAssessmentSubform':
          return new ACCBISTPClinicalAssessmentForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
      case 'awptaCheck1Subform':
          return new ACCBISTPAWPTAForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
      case 'awptaCheck2Subform':
          return new ACCBISTPAWPTA2Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
      case 'awptaCheck3Subform':
          return new ACCBISTPAWPTA3Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
      case 'awptaCheck4Subform':
          return new ACCBISTPAWPTA4Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
      case 'awptaCheck5Subform':
          return new ACCBISTPAWPTA5Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
      case 'bistSymptomScale':
          return new ACCBISTPSymptonScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
      case 'gcsSubform':
          return new ACCBISTPNeuroObsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater, "");
      case 'vitalSignsSubform':
          return new ACCBISTPVitalSignsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
      case 'ctBrainSubform':
          return new ACCBISTPCTBrainForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
    }
  }

  shouldDisplayFormDetailForIdentifier(identifier) {
    super.shouldDisplayFormDetailForIdentifier(identifier);
    let subform = this.subform(identifier);
    if (subform != null && subform != undefined && typeof subform.shouldShowInSidebar === 'function') {
        return subform.shouldShowInSidebar();
    }

    return true;
  }

  isEnabled(identifier) {
    if (identifier != null) {
        return true;
    }
    return false;
  }

  isMandatory() {
    return false;
  }

  getValueType() {
    return null;
  }

  submissionValidation(identifier) {
    return [];
  }

  detailString() {
    return this.detail;
  }
}