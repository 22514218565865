import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationIdentifier } from '../../../waferJS/Form/Validation/FormValidationIdentifier';
import { FormValidationError } from  '../../../waferJS/Form/Validation/FormValidationError';
import { BloodPressure } from '../../../waferJS/Form To FormsUI/Subclasses/BloodPressureTextFormDetail';

FormValidationIdentifier.CheckHeartRate = 'PlausibleHeartRate';
FormValidationIdentifier.CheckBodyTemperature = 'CheckBodyTemperature';
FormValidationIdentifier.CheckOxygenSaturation = 'CheckOxygenSaturation';
FormValidationIdentifier.CheckRespiratoryRate = 'CheckRespiratoryRate';
FormValidationIdentifier.IsBloodPressure = 'IsBloodPressure';
FormValidationIdentifier.CheckGCSRange = 'CheckGCSRange';

let CheckHeartRate = new FormValidation(FormValidationIdentifier.CheckHeartRate, function (value) {
    if (value == null || isNaN(+value) || !isFinite(value)) {
        return [FormValidationError.RequiredError];
    }
    if (value < 30) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Value is unusually low')];
    } else if (value >= 250) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Value is unusually high')];
    }

    return [];
});

let CheckBodyTemperature = new FormValidation(FormValidationIdentifier.CheckBodyTemperature, function (value) {
    if (value == null || isNaN(+value) || !isFinite(value)) {
        return [FormValidationError.RequiredError];
    }

    if (value < 30) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Value is unusually low')];
    } else if (value >= 45) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Value is unusually high')];
    }

    return [];
});

let CheckOxygenSaturation = new FormValidation(FormValidationIdentifier.CheckOxygenSaturation, function (value) {
    if (value == null || isNaN(+value) || !isFinite(value)) {
        return [FormValidationError.RequiredError];
    }

    if (value < 30) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Value is unusually low')];
    } else if (value > 100) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Cannot exceed value of 100%')];
    }
    return [];
});

let CheckRespiratoryRate = new FormValidation(FormValidationIdentifier.CheckRespiratoryRate, function (value) {
    if (value == null || isNaN(+value) || !isFinite(value)) {
        return [FormValidationError.RequiredError];
    }

    if (value > 100) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Value is unusually high')];
    }
    return [];
});

let IsBloodPressure = new FormValidation(FormValidationIdentifier.IsBloodPressure, function (value) {

    var submissionErrors = [];
    if (typeof value != 'string' || value == '' || value == null) {
        return [FormValidationError.RequiredError];
    }

    let bloodPressure = BloodPressure.bloodPressureFromString(value);

    if (bloodPressure == null) {
        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Expected format 000/000')];
    }

    if (bloodPressure.systolic > 300) {
        submissionErrors.push(new FormValidationError(FormValidationError.FormErrorType.warning, 'Value is unusually high'));
    } else if (bloodPressure.systolic <= 50) {
        submissionErrors.push(new FormValidationError(FormValidationError.FormErrorType.warning, 'Value is unusually low'));
    }

    return submissionErrors;
});

let CheckGCSRange = new FormValidation(FormValidationIdentifier.CheckGCSRange, function (value) {

    if (value == null || isNaN(+value) || !isFinite(value)) {
        return [FormValidationError.RequiredError];
    }

    if (value < 3) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Value is unusually low')];
    } else if (value > 15) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Value is unusually high')];
    }
    return [];
});

let checkAge = new FormValidation(FormValidationIdentifier.patientDOB, function (value) {
    // Check if value is null or undefined
    if (value === null || value === undefined) {
        return [FormValidationError.RequiredError];
    }

    // Convert the DOB string to a Date object
    const dob = new Date(value);
    
    // Get current date
    const today = new Date();
    
    // Calculate age
    let age = today.getFullYear() - dob.getFullYear();
    
    // Adjust age if birthday hasn't occurred this year
    const monthDiff = today.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
    }

    if (age < 8) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Patient must be at least 8 years old')]
    }
    if (age > 16) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Patient must be younger than 16 years')]
    }

    if (isNaN(dob.getTime())) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Please enter a valid date')]
    }
    
    if (dob > today) {
        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'Dob cannot be in the future')]
    }
    
    return [];
});

export { CheckHeartRate, CheckBodyTemperature, CheckOxygenSaturation, CheckRespiratoryRate, IsBloodPressure, CheckGCSRange }; 