import { FormWithRedux } from '../../FormWithRedux';
import { FormsStyles } from '../../../waferJS/FormsUI/FormsUI';
import { ACCBISTPCalculationHelper } from '../helpers/ACCBISTPCalculationsHelper';

export class ACCBISTPHighRiskFindingsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('highRiskFindingsSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        return false;
    }

    getValueType(identifier) {
        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }

    getBadgeValue(identifier) {
        let overrideValue = this.getValue(identifier + 'OverrideValue');
        let displayValue = overrideValue;
        if(overrideValue == null){
            displayValue = this.getValue(identifier);
        }
        
        if(displayValue != null){
            return [{
                color: displayValue == true ? FormsStyles.red : FormsStyles.black,
                value: displayValue == true ? 'YES' : 'NO',
                solid: overrideValue != null
            }];
        }
        else {
            return [];
        }
    }

    totalRedFlags() {
        return this.getValue('totalRedFlags') == null ? 0 : this.getValue('totalRedFlags');
    }

    isRedFlagActive(identifier){
        let value = this.getValue(identifier);
        return value; // override == true ? !value : value;
    }

    completionProgress() {
        var totalFormDetails = 0;
        var validFormDetails = 0;
        
        for (let formDetailIdentifier of this.formDetailIdentifiers) {
            let formDetail = this.formDetail(formDetailIdentifier);
            if (formDetail != null && formDetail.canHaveValue) {
                let form = formDetail.form;
                let mandatory = form.isMandatory(formDetailIdentifier);

                let value = this.getValue(formDetail.identifier);

                if (mandatory) {
                    totalFormDetails += 1;

                    if (value != null) {
                        validFormDetails += 1;
                    } 
                }
            }
        }

        for (let subformIdentifier of this.subformIdentifiers) {
            let subform = this.subform(subformIdentifier);
            let subformProgress = subform.completionProgress();
            totalFormDetails += subformProgress.total;
            validFormDetails += subformProgress.current;
        }

        return { current: validFormDetails, total: totalFormDetails };
    }

    getProgress(identifier){
        if(this.getValue(identifier + 'Override') == true){
            if(this.getValue(identifier + 'OverrideReason') != null){
                return 1;
            }
            return 0.5;
        }

        return 1;
    }

    calculationError(identifier){
        let data = null;
        if(this.workflow[this.pathwayId] != null){
            data = this.workflow[this.pathwayId].rawData;
        }
        return ACCBISTPCalculationHelper.calcualtionError(identifier, data, this);
    }


    getOverrideReason(identifier){
        let isOverridden = this.getValue(identifier + 'Override');
        let overrideReason = this.getValue(identifier + 'OverrideReason');
        if(isOverridden){
            return 'Overridden' + (overrideReason == null ? '' :  ': ' + overrideReason);
        }
        return null;
    }

    supportsOverride() {
        return false;
    }

    detailForIdentifier(prefix, value) {
        let badges = this.getBadgeValue(value.identifier);
        if (badges.length > 0) {
            return prefix + value.title + ': ' + (badges[0].value);
        }
        else {
            return super.detailForIdentifier(prefix, value);
        }
        
    }

    //<FormButton key='Submit' onPress={this.toggleReferallVisibility} enabled={true} isSelected={false} title={'Submit'} ignoreTab={true}/>

    detailString(isSubform) {
        if(this.totalRedFlags() == 0) {
            return 'None';
        }

        return super.detailString(isSubform);
        // else {
        //     return this.totalRedFlags() + ' Red flag' + (this.totalRedFlags() == 1 ? '' : 's' );
        // }

        // let formDetails = this.getFormDetails();
        // let values = formDetails.map((value) => {
        //     return value.valueDescription();
        // });

        // if(values.filter(n => n).length == 0){
        //     return this.detail;
        // }
        
        // return values.filter(n => n).join(',\n');
    }
}