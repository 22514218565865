import wayfindAxios from "../components/Common/WayfindAxios";
import { ACC_API_ROOT } from "../config/config";

export interface Patient {
  NHINumber: string;
  FamilyName: string;
  GivenNames: string[] | undefined;
  NameOptions: PatientName[] | undefined;
  Address: string;
  BirthDate: string | undefined;
  Gender: string;
  Ethnicity: string;
  DeceasedDate: string | undefined;
}

export interface PatientName {
  FamilyName: string;
  GivenNames: string[] | undefined;
}

const getPatientByNHINumber = async (nhiNumber: string) => {
  wayfindAxios.defaults.baseURL = ACC_API_ROOT;

  const response = await wayfindAxios.get(
    "api/NHI/GetPatientByNHINumber?patientNHINumber=" + nhiNumber
  );
  return response.data;
};

export default getPatientByNHINumber;
