import { FormWithRedux } from '../../FormWithRedux';
import { redux } from '../../../App';
import { saveAction } from '../../../components/state/actions/ActionActions';
import { CalculationModel } from '../custom types/CalculationModel';
import { saveCalculation } from '../../../components/state/actions/CalculationActions';
import { getPathwayIdFromURL } from '../../../helpers/URLHelper';
import { PathwayReferenceHelper } from '../../PathwayReferenceHelper';
import { ACCBISTPHighRiskFindingsForm } from './ACCBISTP-HighRiskFindingsForm';
import { ACCBISTPCalculationHelper } from '../helpers/ACCBISTPCalculationsHelper';


export class ACCBISTPResultsForm extends FormWithRedux {

    set calculations(value) {
        this._calculations= JSON.parse(JSON.stringify(value));
    }

    get calculations() {
        return this._calculations;
    }

    set actions(value) {
        this._actions = JSON.parse(JSON.stringify(value));
    }

    get actions() {
        return this._actions;
    }

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('resultsForm', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);

        let self = this;
        redux.store.subscribe(() => {
            let state = redux.store.getState();
            self.calculations = state.calculationReducer.calculations;
            self.actions = redux.store.getState().actionsReducer.actions;
        });

        this.calculations = redux.store.getState().calculationReducer.calculations;

        this.actions = redux.store.getState().actionsReducer.actions;
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'highRiskFindingsSubform':
                return new ACCBISTPHighRiskFindingsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        let formDetail = this.formDetail(identifier);
        if(formDetail.controlType == 'action'){
            if(this.actions[this.pathwayId] != null) {
                let pathwayActions = this.actions[this.pathwayId];
                if(pathwayActions == null || JSON.stringify(pathwayActions) == JSON.stringify({})){
                    return null;
                }
                return pathwayActions.filter((action) => {return action.identifier == identifier;}).length > 0;
            }

            return false;
        }
        else if (identifier == 'referralAction') {
            return [ this.shouldDisplayFormDetailForIdentifier('accReferralLowRiskAction'), 
                this.shouldDisplayFormDetailForIdentifier('accReferralModerateRiskAction'),
                this.shouldDisplayFormDetailForIdentifier('physioTherapistReferralAction')
            ].includes(true);
        }
        else if (identifier == 'invalidArrivalGCSWarning') {
            return this.getValue('arrivalGCS') != null && this.getValue('arrivalGCS') != undefined && this.getValue('arrivalGCS') <= 13;
        }

        return true;
    }

    markActionAsObserved(identifier){
        if(this.actions[this.pathwayId] == null ){ return; }
        let matchingActions = this.actions[this.pathwayId].filter((action) => {
            return action.identifier == identifier;
        });

        let filteredActions = this.actions[this.pathwayId].filter((action) => {
            return action.identifier != identifier;
        });

        if (matchingActions.length == 0 ) { return;}

        let action = matchingActions[0];

        if(!action.removeAfterObservation){
            action.observed = true;
            filteredActions.push(action);
        }

        redux.store.dispatch(saveAction(this.pathwayId, filteredActions));
    }

    actionForIdentifier(identifier){
        if(this.actions[this.pathwayId] != null ){
            let filteredAction = this.actions[this.pathwayId].filter((action) => {return action.identifier == identifier;});
            if(filteredAction.length > 0){
                return filteredAction[0];
            }
        }
        
        return null;
    }

    getBadgeValue(identifier) {
        if(identifier == 'bistScoreCalculation') {
            
            let calculation = ACCBISTPCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.bistScoreCalculation, this.calculations[this.pathwayId]);
            
            if(calculation != null){
                return [{
                    color: calculation.badgeColor,
                    value: calculation.valueString,
                    solid: calculation.badgeStyle
                }];
            }
        } 
        else if (identifier == 'bistDominiantSymptomCluster') {
            let calculation = ACCBISTPCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.bistDominantSymptomCluster, this.calculations[this.pathwayId]);
   
            if(calculation != null) {
                return [{
                    color: calculation.badgeColor,
                    value: calculation.valueString,
                    solid: calculation.badgeStyle
                }];
            }
        }
        else if (identifier == 'bistMentalHealthScoreCalculation') {
            let calculation = ACCBISTPCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.bistMentalHealthScoreCalculation, this.calculations[this.pathwayId]);
            if(calculation != null) {
                return [{
                    color: calculation.badgeColor,
                    value: calculation.valueString,
                    solid: calculation.badgeStyle
                }];
            }
        }
        else if (identifier == 'awptaScoreCalculation') {
            let calculation = ACCBISTPCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.awptaScore, this.calculations[this.pathwayId]);
    
            if(calculation != null) {
                return [{
                    color: calculation.badgeColor,
                    value: calculation.valueString,
                    solid: calculation.badgeStyle
                }];
            }
        }
        else if (identifier == 'concussionRiskCategory') {
            let calculation = ACCBISTPCalculationHelper.getLatestCalculation(CalculationModel.CalculationType.concussionRiskCategory, this.calculations[this.pathwayId]);
    
            if(calculation != null) {
                return [{
                    color: calculation.badgeColor,
                    value: calculation.valueString,
                    solid: calculation.badgeStyle
                }];
            }
        }

        return [];
    }

    calculationProgress(identifier) {
        if (identifier == 'awptaScoreCalculation') {
            let requiredIdentifiers = [
                'totalGCS',
                'totalAWPTA',
            ];

            let missingCount = requiredIdentifiers.map((identifier) => {
                return this.getValue(identifier) != null ? true : null;
            }).filter(n => n).length;

            return (missingCount / requiredIdentifiers.length);
        }
        else if(identifier == 'concussionRiskCategory') {
            let requiredIdentifiers = [
                'totalGCS'
            ];

            let missingCount = requiredIdentifiers.map((identifier) => {
                return this.getValue(identifier) != null ? true : null;
            }).filter(n => n).length;

            return (missingCount / requiredIdentifiers.length);
        }
        else if(identifier == 'bistScoreCalculation' || 
            identifier == 'bistDominiantSymptomCluster' ||
            identifier == 'bistMentalHealthScoreCalculation' ) {
            let requiredIdentifiers = [
                'headacheBistRating',
                'neckHurtsBistRating',
                'brightLightsBistRating',
                'loudNoisesBistRating',
                'dizzySickBistRating',
                'closedEyesAtSeaBistRating',
                'troubleWithVisionBistRating',
                'clumsyBistRating',
                'longerToThinkBistRating',
                'forgetThingsBistRating',
                'confusedEasilyBistRating',
                'troubleConcentratingBistRating'
            ];

            var isOver24Hours = false;
            let injuryDateString = this.getValue('injuryOccuranceDateTime');
            if (injuryDateString != undefined && injuryDateString != null) {
                let injuryDate = new Date(injuryDateString);
                let now = new Date();
                let msBetweenDates = Math.abs(injuryDate.getTime() - now.getTime());
                let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
                isOver24Hours = hoursBetweenDates > 24;
            }

            if(isOver24Hours) {
                requiredIdentifiers.push('angryOrIrritatedBistRating');
                requiredIdentifiers.push('dontFeelRightBistRating');
                requiredIdentifiers.push('tiredDuringDayBistRating');
                requiredIdentifiers.push('sleepMoreOrHardToSleepBistRating');
            }

            if(identifier == 'bistMentalHealthScoreCalculation') {
                requiredIdentifiers.push('patientAge');
                requiredIdentifiers.push('confoundingFactors');
                requiredIdentifiers.push('vomiting');
                requiredIdentifiers.push('alterationInMentalState');
                requiredIdentifiers.push('feelingBetterSameWorseSinceInjury');
                requiredIdentifiers.push('previousConcussionOrBrainInjury');
                requiredIdentifiers.push('wasRecoveryProtractedOrProblematic');
                requiredIdentifiers.push('mentalHealthDifficulties');
                requiredIdentifiers.push('historyOfMigraine');
            }

            let missingCount = requiredIdentifiers.map((identifier) => {
                return this.getValue(identifier) != null ? true : null;
            }).filter(n => n).length;
            return (missingCount / requiredIdentifiers.length);
        }

        return 1;
    }

    calculationError(identifier) {
        let data = null;
        if(this.workflow[this.pathwayId] != null){
            data = this.workflow[this.pathwayId].rawData;
        }
        return ACCBISTPCalculationHelper.calcualtionError(identifier, data, this);
    }

    latestCalculation(identifier){
        return ACCBISTPCalculationHelper.getLatestCalculation(this.getTypeFromIdentifier(identifier), this.calculations[this.pathwayId]);
    }

    previousCalculation(identifier){
        return ACCBISTPCalculationHelper.getPreviousCalculation(this.getTypeFromIdentifier(identifier), this.calculations[this.pathwayId]);
    }

    getTypeFromIdentifier(identifier){
        if(identifier == 'awptaScoreCalculation') {
            return CalculationModel.CalculationType.awptaScore;
        } 
        else if(identifier == 'bistScoreCalculation') {
            return CalculationModel.CalculationType.bistScoreCalculation;
        }
        else if(identifier == 'bistDominiantSymptomCluster') {
            return CalculationModel.CalculationType.bistDominantSymptomCluster;
        }
        else if(identifier == 'bistMentalHealthScoreCalculation') {
            return CalculationModel.CalculationType.bistMentalHealthScoreCalculation;
        }
        else if(identifier == 'concussionRiskCategory') {
            return CalculationModel.CalculationType.concussionRiskCategory;
        }
    }

    markCalculationAsObserved(identifier){
        let calculation = this.latestCalculation(identifier);
        if(calculation != null){
            calculation.observed = true;
            redux.store.dispatch(saveCalculation(getPathwayIdFromURL(), calculation, this.calculations));
        }
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        return false;
    }

    getValueType(identifier) {
        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }

    getPlainTextActions(showTitle, identifiers){
        let sectionStrings = this.sectionIds.map((sectionID) => {
            let section = this.sectionReference(sectionID);
            var sectionString = '';
            
            let sectionValues = [];
            if(section[PathwayReferenceHelper.Type.sectionChildIDs] != undefined){
                section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => {
                    if(!identifiers.includes(childID) && identifiers.length > 0 ) { return; }
                    let formDetail = this.formDetail(childID);
                    if(formDetail.controlType == 'action'){
                        let action = this.actionForIdentifier(formDetail.identifier);
                        if(action != null){
                            let actionStringComponents = [];
                            action.title != null ? actionStringComponents.push(this.stripSpecialChars(action.title)) : actionStringComponents.push(this.stripSpecialChars(formDetail.title));
                            action.detail != null ? actionStringComponents.push(this.stripSpecialChars(action.detail)) : actionStringComponents.push(this.stripSpecialChars(formDetail.detail));
                            sectionValues.push(actionStringComponents.filter(n => n).join('\n'));
                        }
                    }
                });

                if(sectionValues.length > 0 ){
                    if(section.title != null && showTitle){
                        sectionString += section.title.toUpperCase() + ': \n';
                    }
                    sectionString += sectionValues.join('\n\n');
                    sectionString += '\n\n';
                }
            }
            return sectionString == '' ? null : sectionString;
        }).filter( n => n);

        return sectionStrings.join('\n\n');
    }

    stripSpecialChars(string) {
        if(string == null || string == undefined) { return string; }
        if(!string.includes('#@')){ return string; } 
        let components = string.split('#@');
        let strippedString = '';
        for(let component of components){
            if(component.includes('^')){
                let subComponents = component.split('^');
                strippedString += subComponents[1];
            } else {
                strippedString += component;
            }
        }
        return strippedString;
    }
}