import React from "react";
import { useNavigate } from "react-router-dom";
import WayFindLogo from "../../images/Wayfind-logo-white.png";
import ChestPainLogo from "../../images/chest-pain.png";
import BrainInjuryLogo from "../../images/brain-injury.png";
import QuidelOrthoBG from "../../images/Quidel-BG.svg";
import QuidelOrthoLogo from "../../images/Quidel-logo.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Button, Grid, IconButton, Popover, Stack, Typography } from "@mui/material";
import HeaderUserProfile from "../Common/HeaderUserProfile";
import { useUserInfo } from "../Hooks/useUserInfo";
import { ENABLED_PATHWAYS } from "../../config/config";

const PathwaySelectionPage = () => {
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePathwaySelect = (pathway: string) => {
    navigate("/" + pathway, { replace: true });
  };

  const chestPainPathwayButton = () => {
    return (
      <Button
        onClick={() => handlePathwaySelect("chestPain01")}
        sx={{ p: 0, borderRadius: "25px", marginX: 2 }}
      >
        <Grid
          item
          container
          direction="row"
          sx={{
            backgroundImage:
              "linear-gradient(90deg,  rgba(50, 139, 183, 1) 0%, rgba(31,163,161,255) 47%,  rgba(45,186,157,255)100%)",
            borderRadius: "25px",
            width: "450px",
            padding: 2,
            textAlign: "left",
            color: "black",
          }}
        >
          <Grid
            item
            xs={8}
            md={8}
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontSize: 25, fontWeight: "bold" }}>
              ED CHEST PAIN SUSPECTED ACS
            </Typography>
          </Grid>
          <Grid
            xs={4}
            md={4}
            item
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <img height={130} width={130} src={ChestPainLogo} alt="placeholder" />
          </Grid>
        </Grid>
      </Button>
    );
  };

  const brainInjuryPathwayButton = () => {
    return (
      <Button
        onClick={() => handlePathwaySelect("accbist01")}
        sx={{ p: 0, borderRadius: "25px", marginX: 2 }}
      >
        <Grid
          item
          container
          direction="row"
          sx={{
            backgroundImage:
              "linear-gradient(90deg,  rgba(50, 139, 183, 1) 0%, rgba(31,163,161,255) 47%,  rgba(45,186,157,255)100%)",
            borderRadius: "25px",
            width: "450px",
            padding: 2,
            textAlign: "left",
            color: "black",
          }}
        >
          <Grid
            item
            xs={8}
            md={8}
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontSize: 25, fontWeight: "bold" }}>
              ACC BRAIN INJURY SCREENING TOOL
            </Typography>
          </Grid>
          <Grid
            xs={4}
            md={4}
            item
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <img height={130} width={130} src={BrainInjuryLogo} alt="placeholder" />
          </Grid>
        </Grid>
      </Button>
    );
  };

  const quidelOrthoPathwayButton = () => {
    return (
      <Button
        onClick={() => handlePathwaySelect("QuidelOrtho2")}
        sx={{ p: 0, borderRadius: "25px", marginX: 2 }}
      >
        <Grid
          item
          container
          direction="row"
          sx={{
            backgroundImage: `url(${QuidelOrthoBG})`,
            borderRadius: "25px",
            width: "450px",
            padding: 2,
            textAlign: "left",
            color: "black",
          }}
        >
          <Grid
            item
            xs={8}
            md={8}
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontSize: 25, fontWeight: "bold" }}>
              QUIDELORTHO (India)
            </Typography>
          </Grid>
          <Grid
            xs={4}
            md={4}
            item
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <img height={130} width={130} src={QuidelOrthoLogo} alt="placeholder" />
          </Grid>
        </Grid>
      </Button>
    );
  };

  const childBrainInjuryPathwayButton = () => {
    return (
      <Button
        onClick={() => handlePathwaySelect("accbist02")}
        sx={{ p: 0, borderRadius: "25px", marginX: 2 }}
      >
        <Grid
          item
          container
          direction="row"
          sx={{
            backgroundImage:
              "linear-gradient(90deg,  rgba(50, 139, 183, 1) 0%, rgba(31,163,161,255) 47%,  rgba(45,186,157,255)100%)",
            borderRadius: "25px",
            width: "450px",
            padding: 2,
            textAlign: "left",
            color: "black",
          }}
        >
          <Grid
            item
            xs={8}
            md={8}
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontSize: 25, fontWeight: "bold" }}>
              ACC BRAIN INJURY SCREENING TOOL (PAEDIATRIC)
            </Typography>
          </Grid>
          <Grid
            xs={4}
            md={4}
            item
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <img height={130} width={130} src={BrainInjuryLogo} alt="placeholder" />
          </Grid>
        </Grid>
      </Button>
    );
  };

  const getButtonByPathwayId = (pathwayId: string) => {
    switch (pathwayId) {
      case "chestPain01":
        return chestPainPathwayButton();
      case "accbist01":
        return brainInjuryPathwayButton();
      case "accbist02":
        return childBrainInjuryPathwayButton();
      case "QuidelOrtho2":
        return quidelOrthoPathwayButton();
      default:
        return undefined;
    }
  };

  return (
    <Box>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundImage:
            "linear-gradient(90deg, rgba(43, 190, 98, 1) 0%, rgba(16, 185, 141, 1) 47%, rgba(50, 139, 183, 1) 100%)",
          padding: 2,
        }}
        direction="row"
      >
        <Box>
          <img src={WayFindLogo} alt="placeholder" />
        </Box>
        <Box>
          <IconButton onClick={handleClick}>
            <AccountCircleIcon
              sx={{
                fontSize: 40,
                color: "white",
              }}
            />
          </IconButton>
        </Box>
      </Stack>

      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{ paper: { sx: { background: "transparent" } } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <HeaderUserProfile
          collapseHeader={false}
          userName={userInfo?.Name ?? ""}
          userEmail={userInfo?.Email ?? ""}
          userHPINumber={userInfo?.HPINumber ?? ""}
          isLowerAccess={false}
        />
      </Popover>
      <Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex", md: "flex" },
            marginY: 5,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Typography variant="h2" sx={{ color: "#0070b7", textAlign: "left", marginY: 3 }}>
            What is Wayfind?
          </Typography>
          <Box
            sx={{
              backgroundColor: "#e3f6f2",
              borderRadius: "25px",
              width: "47.5%",
              padding: 2,
              textAlign: "center",
              color: "black",
            }}
          >
            Wayfind can support any clinical pathway or referral process, and integrates with
            medical knowledge bases such as HealthPathways, as well as healthcare services including
            HCS, Concerto and ERMS, creating a powerful end-to-end workflow for clinicians and
            researchers.
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h2" sx={{ color: "#0070b7", textAlign: "left", marginY: 3 }}>
            Please select a pathway
          </Typography>
          <Grid
            container
            direction="row"
            gap={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {ENABLED_PATHWAYS?.map(pathwayId => getButtonByPathwayId(pathwayId))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PathwaySelectionPage;
