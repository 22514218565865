import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const AuthConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_AUTHORITY ?? "",
    redirectUri: window.location.origin + "/auth",
    protocolMode: "OIDC",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [process.env.REACT_APP_AUTH_SCOPE ?? ""],
  redirectUri: window.location.origin + "/auth",
};
