import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { UserInfo } from "../../api/getUserInfo";

export interface UserInfoReturn {
  instance: IPublicClientApplication;
  userInfo: UserInfo | undefined;
}

export function useUserInfo(): UserInfoReturn {
  const [userInfo, setUserInfo] = useState<UserInfo>();

  const { instance } = useMsal();

  useEffect(() => {
    if (instance && instance.getAllAccounts().length > 0) {
      const currentAccount = instance.getAllAccounts()[0];
      const data: UserInfo = {
        Name: currentAccount?.name ?? "",
        Email: currentAccount?.username,
        ConfidenceLevel: undefined,
        HPINumber: undefined,
      };
      setUserInfo(data);
    }
  }, [instance]);

  return { instance, userInfo };
}
