
export class FormsUIGlossary {
    static required = 'Required';
    static optional = 'Optional';
    static selected = 'Selected';
    static abandoned = 'Abandoned';
    static notSpecified = 'Not specified';
    static titlePrefix = '▪︎';
    static subformDetailPrefix = '- ';
}

export class FormsContentType {
    static ItemType = Object.freeze({
        control: 'control',
        subform: 'subform',
        section: 'section'
    });

    static ControlType = Object.freeze({
        radio: 'radio',
        plain: 'plain',
        picker: 'picker',
        textEntry: 'textEntry',
        selection: 'selection',
        weblink: 'weblink',
        badge: 'badge',
        calculation: 'calculation',
        action: 'action',
        personGraph: 'personGraph',
        redFlag: 'redflag',
        export: 'export',
        noToAll: 'noToAll',
        completionItem: 'completionItem',
        scale: 'scale',
        scaleKey: 'scaleKey',
        pictureGrid: 'pictureGrid',
        pictureGridCheck1: 'pictureGridCheck1',
        callout: 'callout',
        accReferralSubmission: 'accReferralSubmission',
        referralRecommendation: 'referralRecommendation'
    });

    static SubformType = Object.freeze({
        default: 'default',
        suggestive: 'suggestive'
    });

    static SectionStyle = Object.freeze({
        default: 'default',
        inline: 'inline'
    });

    static SelectionType = Object.freeze({
        explicit: 'explicit',
        simple: 'simple',
        dropdown: 'dropdown',
    });

    static TextEntryType = Object.freeze({
        wrappingPlainText: 'wrappingPlainText',
        wrappingPlainTextLarge: 'wrappingPlainTextLarge',
        decimalNumber: 'decimalNumber',
        troponin: 'troponin',
        qoTroponin: 'qoTroponin',
        qoTroponin2: 'qoTroponin2',
        singleLinePlainText: 'singleLinePlainText',
        singleLongLinePlainText: 'singleLongLinePlainText',
        plainTextWithNHILookup: 'plainTextWithNHILookup',
        decimalNumberWithConsciousnessAlert: 'decimalNumberWithConsciousnessAlert',
        patientNameCombobox: 'patientNameCombobox'
    });

    static PickerType = Object.freeze({
        dateTime: 'dateTime',
        date: 'date',
        dateOfBirth: 'dateOfBirth',
        bloodPressure: 'bloodPressure'
    });
}

export class FormsStyles {
    
    static quidelStyle = false;
    
    static get primaryTint () { return FormsStyles.quidelStyle ? 'rgb(119, 78, 239)' : 'rgb(1, 156, 255)';}
    static secondaryTint = '#B8DAF2';

    // static backgroundColor = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'rgba(255, 255, 255, 0.07)' : 'rgba(76, 125, 175, 0.07)';
    // static solidBackgroundColor = window.matchMedia('(prefers-color-scheme: dark)').matches ? '#262626' : '#F0F5FA';
    static backgroundColor = 'rgba(76, 125, 175, 0.07)';
    static solidBackgroundColor = '#F0F5FA';

    static lightestGray = 'rgba(0, 0, 0, 0.08)';
    static lightGray = 'rgba(0, 0, 0, 0.3)';

    // static defaultText = window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : '#181818';
    // static bodyText = window.matchMedia('(prefers-color-scheme: dark)').matches ? '#7E7E7E' : '#4A4A4A';
    static defaultText = '#181818';
    static bodyText = 'rgb(176, 176, 176)';

    static red = '#FF2808';
    static yellow = '#F1D401'
    static blue = '#5FB2E1';
    static orange = '#FFAB2A';
    static white = '#FFFFFF';
    static green = '#7ED321'
    static black = '#000000';
    static white = '#FFFFFF';
    static grey = 'rgba(0, 0, 0, 0.5)';

    static enabledStyle(isEnabled) {
        return {
            pointerEvents: isEnabled ? 'all' : 'none',
            opacity: isEnabled ? '1' : '0.5',
            cursor: isEnabled ? 'pointer' : 'default'
        };
    }
}