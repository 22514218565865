import { FormWithRedux } from '../../FormWithRedux';

export class ACCBISTPPost24HourScaleForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('post24HoursBistSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        let injuryDateString = this.getValue('injuryOccuranceDateTime');  
        if (injuryDateString != undefined && injuryDateString != null) {
            let injuryDate = new Date(injuryDateString);
            let now = new Date();
            let msBetweenDates = Math.abs(injuryDate.getTime() - now.getTime());
            let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
            return hoursBetweenDates > 24;
        }
        else {
            return false;
        }
        
    }

    getValueType(identifier) {
        return 'double';
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }
}