import React, { useEffect, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { FormContentView } from "../../FormsUI/FormsUI Components/Containers/FormContentView";
import { FormsStyles } from "../../FormsUI/FormsUI";

interface PatientNameComboBoxFormDetailProp {
  formDetail: any;
  errorLabels: string[];
  rawData: { [key: string]: any };
  hasError: boolean;
}

const PatientNameComboBoxFormDetail: React.FC<PatientNameComboBoxFormDetailProp> = (props) => {

  const [textValue, setTextValue] = useState<string>(props.formDetail.editingValue ?? "");
  const [nameOptions, setNameOptions] = useState<string[]>([props.formDetail.editingValue]);

  useEffect(() => {
    var optionText = props.formDetail.form.getValue("patientNameOptions");
    var options = optionText?.split(",") ?? [props.formDetail.editingValue];
    setTextValue(props.formDetail.editingValue ?? options[0]);
    setNameOptions(options);
  }, [props.rawData]);

  const textFieldUpdated = (value: string) => {
    props.formDetail.form.valueUpdated(props.formDetail.identifier, value);
  };

  const createComboBox = () => {
    const textField = (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Autocomplete
          options={nameOptions}
          value={textValue}
          onInputChange={(e,value)=> textFieldUpdated(value)}
          autoComplete={false}
          freeSolo={true}
          disableClearable={true}
          classes={{
            popper: "{width:fit-content}"
          }}
          fullWidth
          sx={{
            minWidth: "26em",
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              right: 0,
              textAlign: "right",
              py:0
            }
          }}
          renderInput={(params) => 
              <TextField {...params} 
                sx={{
                  color: FormsStyles.primaryTint,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    border: "2px solid " + FormsStyles.backgroundColor,
                    "& :focus": { color: "black" },
                    color: FormsStyles.primaryTint,
                  },
                  "& .MuiInputBase-input": {
                    py: 1,
                    overflow: "hidden",
                    textOverflow: "clip"
                  },
                  "& fieldset": { border: "none" },
                }}  
                variant="outlined" />
            }
        />
      </Box>
    );

    return textField;
  };

  return (
    <FormContentView
      identifier={props.formDetail.identifier}
      isSubcell={false}
      titleString={props.formDetail.title}
      validation={props.errorLabels}
      isMandatory={props.formDetail.form.isMandatory(props.formDetail.identifier)}
      detailString={props.formDetail.detail}
      rightControl={createComboBox()}
      subcell={undefined}
      subCellbackgroundColor={FormsStyles.backgroundColor}
      showHover={true}
    />
  );
};

export default PatientNameComboBoxFormDetail;
