import { FormWithRedux } from '../../FormWithRedux';

export class ACCBISTPAnticoagulantAntiplateletForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('anticoagulantAntiplateletSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'aspirin' || identifier == 'clopidogrelOrTicagrelor' || identifier == 'warfarin' || identifier == 'revaroxabanOrDabigatran' || identifier == 'otherMedication' ) {
            return this.getValue('anticoagulantOrAntiplateletAgent') == true; 
        }

        return true;
    }

    getValueType(identifier) {
        return 'boolean';
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }
}